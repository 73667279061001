import React, { useContext, useEffect } from "react";
import { Helmet } from "react-helmet-async";

import { Context } from "@reactivated";

import { injectScript } from "../../utils/dom";

import "./index.module.scss";

interface Props {}

export const PageHeader = (props: Props) => {
    const context = useContext(Context);
    useEffect(() => {
        // Inject the script as an effect instead of using Helmet since doesn't work when placed in html > head. It
        // must run after the DOM content loads and #headerApp exists.
        context.RL_HEADER_JS.map(injectScript);
    }, []);
    return (
        <>
            <Helmet>
                {context.RL_HEADER_CSS.map((url) => (
                    <link key={url} href={url} rel="stylesheet" />
                ))}
            </Helmet>
            <div id="headerApp"></div>
        </>
    );
};
