import React from "react";

import { MediaGridBlockVideo as Value } from "@reactivated";

import { VideoChooserBlock } from "@thelabnyc/thelabui/src/components/VideoChooserBlock";

import { TileContainer } from "./TileContainer";

import styles from "./VideoTile.module.scss";

interface Props {
    value: Value;
    numSiblings: number;
}

export const VideoTile = (props: Props) => {
    return (
        <TileContainer aspectRatio={16 / 9} numSiblings={props.numSiblings}>
            {props.value.video && (
                <VideoChooserBlock
                    value={props.value.video}
                    attrs={{
                        className: styles.video,
                    }}
                />
            )}
            <figcaption>{props.value.caption}</figcaption>
        </TileContainer>
    );
};
