import React from "react";

import { TwoColumnFeatureBlock as Value } from "@reactivated";

import { MediaChooserBlock } from "@thelabnyc/thelabui/src/components/MediaChooserBlock";
import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import { AlignedRichTextBlock } from "../AlignedRichTextBlock";
import { LinkSubBlock } from "../LinkSubBlock";

import styles from "./index.module.scss";

interface Props {
    value: Value;
}

export const TwoColumnFeatureBlock = (props: Props) => {
    const getStyleClass = (style: string | null) => {
        switch (style) {
            case "media-right": {
                return styles.mediaRight;
            }
            default:
                return undefined;
        }
    };
    const getMediaAlignmentClass = (style: string | null) => {
        switch (style) {
            case "align-left": {
                return styles.alignMediaLeft;
            }
            case "align-center": {
                return styles.alignMediaCenter;
            }
            case "align-right": {
                return styles.alignMediaRight;
            }
            default:
                return undefined;
        }
    };
    if (!props.value.content || !props.value.media) {
        return null;
    }

    const textAlignment =
        props.value.content.content?.alignment === ""
            ? styles.alignLeft
            : props.value.content.content?.alignment === "textAlignRight"
              ? styles.alignRight
              : props.value.content.content?.alignment === "textAlignCenter"
                ? styles.alignCenter
                : null;

    return (
        <section
            className={concatClassNames([
                styles.container,
                getStyleClass(props.value.column_layout),
            ])}
        >
            <div
                className={concatClassNames([
                    styles.featureMedia,
                    getMediaAlignmentClass(props.value.media_alignment),
                ])}
            >
                <MediaChooserBlock
                    value={props.value.media}
                    dependencies={{
                        ImageChooserBlock: (imgProps) => (
                            <img
                                className={styles.featureImg}
                                src={imgProps.value.url}
                                alt=""
                            />
                        ),
                    }}
                />
                {props.value.media_caption && (
                    <p className={styles.mediaCaption}>
                        {props.value.media_caption}
                    </p>
                )}
            </div>
            <div className={styles.featureText}>
                <AlignedRichTextBlock value={props.value.content}>
                    {props.value.links && (
                        <p
                            className={concatClassNames([
                                styles.links,
                                textAlignment,
                            ])}
                        >
                            {props.value.links.map((link) => (
                                <LinkSubBlock key={link.url} value={link} />
                            ))}
                        </p>
                    )}
                </AlignedRichTextBlock>
            </div>
        </section>
    );
};
