import React from "react";

import { TwoColumnHeroBlock as Value } from "@reactivated";

import { HeroBlock } from "../HeroBlock";

import styles from "./index.module.scss";

interface Props {
    value: Value;
}

export const TwoColumnHeroBlock = (props: Props) => {
    if (!props.value.columns) {
        return null;
    }
    return (
        <section className={styles.container}>
            {props.value.columns.map((hero, i) => {
                return <HeroBlock key={i} value={hero} />;
            })}
        </section>
    );
};
