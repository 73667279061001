import React from "react";

import { templates } from "@reactivated";

import { BackgroundStyle } from "../components/BackgroundStyle";
import { ContactUsForm } from "../components/ContactUsForm";
import { PageContentContainer } from "../components/PageContentContainer";
import { PageSkeleton } from "../components/PageSkeleton";
import { Toolbox } from "../components/Toolbox";

export const Template = (props: templates.ContactUsPageTemplate) => (
    <React.StrictMode>
        <PageSkeleton {...props.page}>
            <BackgroundStyle style="wood-texture">
                <Toolbox value={props.page.body.value} />
                <PageContentContainer>
                    <ContactUsForm form={props.form} />
                </PageContentContainer>
            </BackgroundStyle>
        </PageSkeleton>
    </React.StrictMode>
);
