import React from "react";

import { templates } from "@reactivated";

import { BackgroundStyle } from "../components/BackgroundStyle";
import { EmailSignupForm } from "../components/EmailSignupForm";
import { PageContentContainer } from "../components/PageContentContainer";
import { PageSkeleton } from "../components/PageSkeleton";
import { Toolbox } from "../components/Toolbox";

export const Template = (props: templates.EmailSignupPageTemplate) => (
    <React.StrictMode>
        <PageSkeleton {...props.page}>
            <BackgroundStyle style="wood-texture">
                <Toolbox value={props.page.body.value} />
                <Toolbox value={props.page.pre_form_content.value} />
                <PageContentContainer>
                    <EmailSignupForm
                        form={props.form}
                        occasionFormSet={props.occasion_formset}
                        termsPage={props.page.terms_page}
                        privacyPage={props.page.privacy_page}
                        faqPage={props.page.faq_page}
                        unsubscribePage={props.page.unsubscribe_page}
                        consentText={props.page.consent_text}
                        postConsentText={props.page.post_consent_text}
                        footerText={props.page.footer_text}
                    />
                </PageContentContainer>
            </BackgroundStyle>
        </PageSkeleton>
    </React.StrictMode>
);
