import React from "react";

import { TimelineBlock as Value, _Event } from "@reactivated";

import { ImageChooserBlock } from "@thelabnyc/thelabui/src/components/ImageChooserBlock";
import { RichTextBlock } from "@thelabnyc/thelabui/src/components/RichText";
import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import { Layout } from "../Layout";

import styles from "./index.module.scss";

interface Props {
    value: Value;
}

export const Event = (props: { value: _Event }) => {
    return (
        <div className={styles.event}>
            <span className={styles.year}>{props.value.year}</span>
            {props.value.content && props.value.content[0].type === "copy" && (
                <RichTextBlock
                    className="rich-text"
                    value={props.value.content[0].value}
                />
            )}
            {props.value.content && props.value.content[0].type === "image" && (
                <ImageChooserBlock value={props.value.content[0].value} />
            )}
        </div>
    );
};

export const TimelineBlock = (props: Props) => {
    if (!props.value.events) {
        return null;
    }
    return (
        <Layout style={props.value.layout ? props.value.layout : ""}>
            <section>
                {props.value.header && (
                    <RichTextBlock
                        className={concatClassNames([
                            styles.header,
                            "rich-text",
                        ])}
                        value={props.value.header}
                    />
                )}
                {props.value.events.map((event, i) => {
                    return <Event key={i} value={event} />;
                })}
                {props.value.footer && (
                    <RichTextBlock
                        className={concatClassNames([
                            styles.footer,
                            "rich-text",
                        ])}
                        value={props.value.footer}
                    />
                )}
            </section>
        </Layout>
    );
};
