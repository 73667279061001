import React, { useContext } from "react";

import { Context, RegionNav as RegionNavValue } from "@reactivated";

import styles from "./RegionNav.module.scss";

export const RegionNav = (props: { value: RegionNavValue }) => {
    const context = useContext(Context);
    const requestURL = context.request.url;
    // Filter out the region that we're currently viewing.
    const regions = props.value.regions.filter((region) => {
        return requestURL.indexOf(region.url) !== 0;
    });
    return (
        <div className={styles.regionNav}>
            {regions.map((page) => (
                <a
                    key={page.id}
                    href={page.url}
                    className={styles.regionNavLink}
                >
                    {page.title}
                </a>
            ))}
        </div>
    );
};
