import React, { useEffect, useState } from "react";

import { FieldHandler } from "@reactivated";

import { getLocation, listLocations } from "../../utils/api";
import { geocode } from "../../utils/maps";
import {
    Location,
    RLID,
    fromGoogleLatLng,
    isoMiles,
    isoRLID,
} from "../../utils/models";
import { LocationSelectInlineSearch } from "./LocationSelectInlineSearch";
import { LocationSelectModal } from "./LocationSelectModal";

const QUERY_RADIUS = isoMiles.wrap(200);
const QUERY_LIMIT = 6;

const searchLocations = async (searchText: string): Promise<Location[]> => {
    const results = await geocode({
        address: searchText,
    });
    const result = results[0];
    if (!result?.geometry?.location) {
        console.error("Result has no geometry info");
        return [];
    }

    const resp = await listLocations({
        center: fromGoogleLatLng(result.geometry.location),
        radius: QUERY_RADIUS,
        limit: QUERY_LIMIT,
    });
    return resp.locations.map((locProx) => locProx.location);
};

interface IProps {
    formField: FieldHandler;
    initialValue: string | null;
}

export const LocationSelect = (props: IProps) => {
    const initialValue = props.initialValue
        ? isoRLID.wrap(parseInt(props.initialValue, 10))
        : null;

    const [searchText, setSearchText] = useState("");
    const [locations, setLocations] = useState<Location[]>([]);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedRLID, setSelectedRLID] = useState<RLID | null>(initialValue);

    // Anytime selectedRLID changes, if the new value isn't in the locations
    // array, load it from the API. This really only happens when the form gets
    // loaded with an initial value (e.g. because of validation errors).
    const loadLocation = async (rlid: RLID) => {
        const location = await getLocation(rlid);
        setLocations((locations) => {
            return locations.concat([location]);
        });
    };
    useEffect(() => {
        const location = locations.find((l) => l.rlid === selectedRLID);
        if (selectedRLID && !location) {
            loadLocation(selectedRLID).catch((err) => {
                console.error(err);
            });
        }
    }, [selectedRLID]);

    const onSearch = async () => {
        const locations = await searchLocations(searchText);
        setLocations(locations);
        setModalIsOpen(true);
    };

    const onSelect = (rlid: RLID) => {
        setModalIsOpen(false);
        setSelectedRLID(rlid);
    };

    const onChangeSelection = () => {
        setModalIsOpen(true);
    };

    const selectedLocation = locations.find((l) => l.rlid === selectedRLID);

    return (
        <div>
            <input
                type="hidden"
                name={props.formField.name}
                value={selectedRLID ? isoRLID.unwrap(selectedRLID) : ""}
            />
            <LocationSelectInlineSearch
                searchText={searchText}
                selectedLocation={selectedLocation || null}
                setSearchText={setSearchText}
                onSearch={onSearch}
                onChangeSelection={onChangeSelection}
            />
            <LocationSelectModal
                searchText={searchText}
                locations={locations}
                isOpen={modalIsOpen}
                setIsOpen={setModalIsOpen}
                setSearchText={setSearchText}
                onSearch={onSearch}
                onSelect={onSelect}
                onSeeMore={() => {}}
            />
        </div>
    );
};
