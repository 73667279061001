import React from "react";

import { templates } from "@reactivated";

import { BackgroundStyle } from "../components/BackgroundStyle";
import { PageSkeleton } from "../components/PageSkeleton";
import { Toolbox } from "../components/Toolbox";

export const Template = (props: templates.UtilityPageTemplate) => (
    <React.StrictMode>
        <PageSkeleton {...props.page}>
            <BackgroundStyle style={props.page.background_style}>
                <Toolbox value={props.page.body.value} />
            </BackgroundStyle>
        </PageSkeleton>
    </React.StrictMode>
);
