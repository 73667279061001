import React from "react";
import { t } from "ttag";

import { RichTextBlock as RichTextBlockValue } from "@reactivated";

import { RichTextBlock } from "@thelabnyc/thelabui/src/components/RichText";

import accessinButtonImg from "../../../static/img/accessin-button-web-redonblack.png";

import styles from "./LegalText.module.scss";

export const LegalText = (props: { value: RichTextBlockValue }) => (
    <div className={styles.legalText}>
        <RichTextBlock value={props.value} />

        <form
            action="https://accessin.org/app/accessin/index.php/welcome2/index_AccessIN"
            className="access-in-form"
            id="AccessIN_form"
            method="post"
            target="_blank"
        >
            <input
                id="AccessIN_url"
                name="ai_url"
                type="hidden"
                value="unset"
            />
            <input
                id="AccessIN_dom"
                name="ai_dom"
                type="hidden"
                value="unset"
            />
            <input
                id="AccessIN_screenWidth"
                name="ai_screenWidth"
                type="hidden"
                value="unset"
            />
            <input
                id="AccessIN_screenHeight"
                name="ai_screenHeight"
                type="hidden"
                value="unset"
            />
            <p>
                <br />
                {t`Report a website accessibility issue:`}
                <br />
                <input
                    alt={t`AccessIn Accessibility Service. Feedback form opens in a new window`}
                    src={accessinButtonImg}
                    type="image"
                    width="40"
                />
            </p>
        </form>
    </div>
);
