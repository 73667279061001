import { Hero } from "@client/components/HeroBlock";
import React from "react";

import { templates } from "@reactivated";

import locationHeroImage from "../../static/img/rl-locations-page-header-image.png";
import { Address } from "../components/Address";
import { BackgroundStyle } from "../components/BackgroundStyle";
import { PageSkeleton } from "../components/PageSkeleton";

import styles from "../styles/templates/LocationsPageLocationListTemplate.module.scss";

export const Template = (
    props: templates.LocationsPageLocationListTemplate,
) => (
    <React.StrictMode>
        <PageSkeleton {...props.page}>
            <BackgroundStyle style="wood-texture">
                <section className={styles.container}>
                    <Hero
                        imageURL={locationHeroImage}
                        imageAlignment="align-center"
                        textStyle="light"
                        title={`Red Lobster Locations in ${props.city_name}, ${props.state_code}.`}
                        subtitle="For hours, menus and more, choose a local Red Lobster below."
                    />
                    <div className={styles.navLinks}>
                        <a
                            className={styles.moreLocationsLink}
                            href={props.state_list_url}
                        >
                            More {props.page.country.printable_name} Locations
                        </a>
                    </div>
                    <ul className={styles.locations}>
                        {props.locations.map((location, i) => (
                            <li key={i}>
                                <a
                                    className={styles.link}
                                    key={location.id}
                                    href={location.url}
                                >
                                    <Address
                                        key={location.id}
                                        location={location}
                                    />
                                    <strong>View Local Page</strong>
                                </a>
                            </li>
                        ))}
                    </ul>
                </section>
            </BackgroundStyle>
        </PageSkeleton>
    </React.StrictMode>
);
