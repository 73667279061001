import React from "react";

import {
    ButtonPrimary,
    ButtonSecondary,
    Clickable,
    TextLink,
} from "@thelabnyc/thelabui/src/components/Clickable";
import {
    LinkStyle,
    LinkSubBlockClickableMap,
    createLinkSubBlockComponent,
} from "@thelabnyc/thelabui/src/components/LinkSubBlock";

import styles from "./index.module.scss";

export const clickableMap: LinkSubBlockClickableMap<LinkStyle> = {
    "": (props) => (
        <ButtonPrimary className={styles.primaryButton} {...props} />
    ),
    "secondary": (props) => (
        <ButtonSecondary className={styles.secondaryButton} {...props} />
    ),
    "text-link": TextLink,
    "unstyled": Clickable,
};

export const LinkSubBlock = createLinkSubBlockComponent(clickableMap);
