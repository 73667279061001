import { format as formatDate, parseISO as parseISODate } from "date-fns";
import React from "react";

import { ArticlePreviewGridBlock as Value, _Article } from "@reactivated";

import { ImageChooserBlock } from "@thelabnyc/thelabui/src/components/ImageChooserBlock";

import styles from "./index.module.scss";

interface Props {
    value: Value;
}

interface IGridItem {
    value: _Article;
}

export const ArticlePreviewGridItem = (props: IGridItem) => {
    const altText = props.value.image ? props.value.image.title : undefined;
    const renderDate = (date: string) => {
        return formatDate(parseISODate(date), "M.d.yy");
    };
    if (!props.value.link) {
        return null;
    }
    return (
        <a className={styles.link} href={props.value.link.url}>
            {props.value.image && (
                <ImageChooserBlock
                    value={props.value.image}
                    imageAttrs={{
                        alt: altText,
                        className: styles.image,
                    }}
                />
            )}
            {props.value.date && (
                <time dateTime={props.value.date} className={styles.date}>
                    {renderDate(props.value.date)}
                </time>
            )}
            {props.value.title && (
                <h3 className={styles.title}>{props.value.title}</h3>
            )}
        </a>
    );
};

export const ArticlePreviewGridBlock = (props: Props) => {
    if (!props.value.articles) {
        return null;
    }
    return (
        <section className={styles.wrapper}>
            <div className={styles.container}>
                {props.value.articles.map((article, i) => {
                    return <ArticlePreviewGridItem key={i} value={article} />;
                })}
            </div>
            {props.value.see_all_link && (
                <a
                    className={styles.seeAllLink}
                    href={props.value.see_all_link.url}
                >
                    See All
                </a>
            )}
        </section>
    );
};
