import { reverse } from "@reactivated";

import { loadCSRFToken } from "@thelabnyc/thelabui/src/utils/api";

import {
    LatLng,
    Location,
    LocationQueryResponse,
    Miles,
    RLID,
    UnsubscribeResponse,
    check,
    isoLatitude,
    isoLongitude,
    isoMiles,
    isoRLID,
} from "./models";

export type LocationQuery = {
    center: LatLng;
    radius: Miles;
    limit: number;
};

/**
 * Query the retail location API for the locations within the circle defined by
 * the center and radius parameters.
 */
export const listLocations = async (
    query: LocationQuery,
): Promise<LocationQueryResponse> => {
    const url = reverse("locations-api:locationlist");
    const querystring = new URLSearchParams({
        latitude: `${isoLatitude.unwrap(query.center.lat)}`,
        longitude: `${isoLongitude.unwrap(query.center.lng)}`,
        radius: `${isoMiles.unwrap(query.radius)}`,
        limit: `${query.limit}`,
    });
    const response = await fetch(`${url}?${querystring.toString()}`);
    const data: unknown = await response.json();
    return check(LocationQueryResponse.decode(data));
};

/**
 * Get a location by ID.
 */
export const getLocation = async (rlid: RLID): Promise<Location> => {
    const url = reverse("locations-api:locationdetail");
    const querystring = new URLSearchParams({
        rlid: `${isoRLID.unwrap(rlid)}`,
    });
    const response = await fetch(`${url}?${querystring.toString()}`);
    const data: unknown = await response.json();
    return check(Location.decode(data));
};

/**
 * Unsubscribe the given email from the mailing list.
 */
export const unsubscribeEmail = async (
    email: string,
): Promise<UnsubscribeResponse> => {
    const url = reverse("api-fcc-unsubscribe");
    const pageQS = new URL(window.location.href).searchParams;
    const postBody = new URLSearchParams({
        Email: email,
    });
    const blid = pageQS.get("blid");
    if (blid) {
        postBody.set("BroadLogId", blid);
    }

    const csrfToken = await loadCSRFToken();
    const response = await fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "X-CSRFToken": await loadCSRFToken(),
        },
        body: postBody.toString(),
    });

    const respData: unknown = await response.json();
    return check(UnsubscribeResponse.decode(respData));
};
