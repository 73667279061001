import React, { useContext } from "react";

import { Context, RichTextField as RichTextFieldValue } from "@reactivated";

import { RichText } from "@thelabnyc/thelabui/src/components/RichText";
import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import styles from "./Legal.module.scss";

export const Legal = (
    props: React.PropsWithChildren<{
        postConsentText: RichTextFieldValue;
        className?: string;
    }>,
) => {
    const context = useContext(Context);
    const requestURL = new URL(context.request.url);
    return (
        <section className={concatClassNames([styles.legal, props.className])}>
            <p>
                You may withdraw your consent at any time by clicking on the
                unsubscribe link below, in the bottom of our emails, or by
                visiting our website at{" "}
                <a target="_blank" rel="noreferrer" href={requestURL.origin}>
                    {requestURL.host}
                </a>
                .
            </p>

            {props.children}

            <RichText value={props.postConsentText} />
        </section>
    );
};
