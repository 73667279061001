import React from "react";

import { ServerCmsModelsPagesLocationsDirectoryPageLocation as Location } from "@reactivated";

import styles from "./DirectionsLink.module.scss";

export const DirectionsLink = (props: { location: Location }) => {
    if (!props.location.location) {
        return null;
    }
    return (
        <a
            className={styles.link}
            href={`https://www.google.com/maps/dir/Current+Location/${props.location.location.lat},${props.location.location.lng}`}
            target="_blank"
            rel="noreferrer"
        >
            Get directions
        </a>
    );
};
