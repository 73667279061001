import React, { useState } from "react";

import { geocode } from "../../utils/maps";
import { LatLng, fromGoogleLatLng } from "../../utils/models";

import styles from "./UpdateLocationForm.module.scss";

export const UpdateLocationForm = (props: {
    onLocationUpdate: (point: LatLng) => void;
}) => {
    const [inputText, setInputText] = useState("");

    const onSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        const results = await geocode({
            address: inputText,
        });
        const result = results[0];
        if (!result?.geometry?.location) {
            console.error("Result has no geometry info");
            return;
        }
        const point = fromGoogleLatLng(result.geometry.location);
        props.onLocationUpdate(point);
    };

    return (
        <form className={styles.form} onSubmit={onSubmit}>
            <h1 className={styles.heading}>
                Find a different
                <br /> Red Lobster
            </h1>
            <label
                className={styles.label}
                id="enter-zip-label"
                htmlFor="enter-zip"
            >
                Search for a Red Lobster by city, state, or ZIP
            </label>
            <input
                aria-labelledby="enter-zip-label"
                id="enter-zip"
                className={styles.input}
                type="text"
                placeholder="City, State, or ZIP"
                autoComplete="off"
                value={inputText}
                onChange={(e) => {
                    setInputText(e.currentTarget.value);
                }}
            />
            <input className={styles.button} type="submit" value="Go" />
        </form>
    );
};
