import React from "react";

import { FreshCatchFeatureBlock as Value } from "@reactivated";

import styles from "./index.module.scss";

interface Props {
    value: Value;
}

export const FreshCatchFeatureBlock = (props: Props) => {
    if (!props.value.link) {
        return null;
    }
    return (
        <section className={styles.root}>
            <h2 className={styles.title}>
                <span className={styles.fresh}>Fresh</span>
                <span className={styles.catch}>Catch</span>
                <span className={styles.news}>News</span>
            </h2>
            <div className={styles.divider}>
                <svg
                    height="45px"
                    width="45px"
                    viewBox="0 0 1900 1300"
                    transform="scale(1,-1)"
                >
                    <path
                        d={`M960 1280q0 26 -19 45t-45 19t-45 -19t-19 -45t19 -45t45 -19t45 19t19 45zM1792 352v-352q0 -22 -20 -30q-8 -2 -12 -2q-12 0 -23 9l-93 93q-119 -143 -318.5 -226.5t-429.5 -83.5t-429.5 83.5t-318.5 226.5l-93 -93q-9 -9 -23 -9q-4 0 -12 2q-20 8 -20 30v352
        q0 14 9 23t23 9h352q22 0 30 -20q8 -19 -7 -35l-100 -100q67 -91 189.5 -153.5t271.5 -82.5v647h-192q-26 0 -45 19t-19 45v128q0 26 19 45t45 19h192v163q-58 34 -93 92.5t-35 128.5q0 106 75 181t181 75t181 -75t75 -181q0 -70 -35 -128.5t-93 -92.5v-163h192q26 0 45 -19
        t19 -45v-128q0 -26 -19 -45t-45 -19h-192v-647q149 20 271.5 82.5t189.5 153.5l-100 100q-15 16 -7 35q8 20 30 20h352q14 0 23 -9t9 -23z`}
                    />
                </svg>
            </div>
            <h3 className={styles.subtitle}>
                Join to get a free appetizer or dessert,
                <br />
                plus other irresistible deals.
            </h3>
            <a
                href={props.value.link.url ? props.value.link.url : ""}
                className={styles.button}
            >
                {props.value.link.display_name}
            </a>
        </section>
    );
};
