import { useEffect, useState } from "react";

import { listLocations } from "../utils/api";
import {
    LatLng,
    LocationQueryResponse,
    isoLatitude,
    isoLongitude,
    isoMiles,
} from "../utils/models";

const QUERY_RADIUS = isoMiles.wrap(200);

const QUERY_LIMIT = 26;

export type UserLocation = {
    detected: LatLng | null;
    entered: LatLng | null;
};

const DEFAULT_LOCATION: UserLocation = {
    detected: null,
    entered: null,
};

export const useUserLocation = () => {
    const [userLocation, setUserLocation] =
        useState<UserLocation>(DEFAULT_LOCATION);

    // Find the user's location using the navigator.geolocation API
    const getBrowserGeoLocation = () => {
        const onSuccess: PositionCallback = (pos) => {
            setUserLocation((s) => ({
                ...s,
                detected: {
                    lat: isoLatitude.wrap(pos.coords.latitude),
                    lng: isoLongitude.wrap(pos.coords.longitude),
                },
            }));
        };
        const onError: PositionErrorCallback = (err) => {
            console.error(err);
        };
        const options: PositionOptions = {
            enableHighAccuracy: false,
        };
        navigator.geolocation.getCurrentPosition(onSuccess, onError, options);
    };

    // Find the user's location using query params in the URL (passed to us by
    // the header code).
    const getURLGeoLocation = () => {
        if (!window.location.search) {
            return false;
        }
        const urlParams = new URLSearchParams(window.location.search);
        const lat = parseFloat(urlParams.get("latitude") || "");
        const lng = parseFloat(urlParams.get("longitude") || "");
        if (isNaN(lat) || isNaN(lng)) {
            return false;
        }
        setUserLocation((s) => ({
            ...s,
            detected: {
                lat: isoLatitude.wrap(lat),
                lng: isoLongitude.wrap(lng),
            },
        }));
        return true;
    };

    useEffect(() => {
        const success = getURLGeoLocation();
        if (!success) {
            getBrowserGeoLocation();
        }
    }, []);

    const setEnteredLocation = (enteredLocation: LatLng | null): void => {
        setUserLocation((s) => ({
            ...s,
            entered: enteredLocation,
        }));
    };

    return {
        userLocation,
        setEnteredLocation,
    };
};

export const useNearbyRetailLocations = (userLocation: UserLocation) => {
    const [queryResp, setQueryResp] = useState<LocationQueryResponse | null>(
        null,
    );

    const updateLocations = async (): Promise<void> => {
        const center = userLocation.entered || userLocation.detected;
        if (!center) {
            return;
        }
        const resp = await listLocations({
            center: center,
            radius: QUERY_RADIUS,
            limit: QUERY_LIMIT,
        });
        setQueryResp(resp);
    };

    useEffect(() => {
        updateLocations().catch((err) => {
            console.error(err);
        });
    }, [userLocation]);

    return queryResp;
};
