import React from "react";

import { Miles, isoMiles } from "../../utils/models";

// TODO: Show KM instead of miles in Canada
export const Distance = (props: { className?: string; distance: Miles }) => (
    <p className={props.className}>
        {isoMiles.unwrap(props.distance).toFixed(2)} mi
    </p>
);
