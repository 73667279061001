import React from "react";

import styles from "./index.module.scss";

interface Props {
    style: string;
    children: React.ReactNode;
}

const getStyleClass = (style: string) => {
    switch (style) {
        case "wood-texture": {
            return styles.woodTexture;
        }
        case "lighthouse": {
            return styles.lighthouse;
        }
        case "faded-shoreline": {
            return styles.fadedShoreline;
        }
        case "military-monday": {
            return styles.militaryMonday;
        }
        case "lobster": {
            return styles.lobster;
        }
        case "blue-chill": {
            return styles.blueChill;
        }
        default:
            return undefined;
    }
};

export const BackgroundStyle = (props: Props) => (
    <div className={getStyleClass(props.style)}>{props.children}</div>
);
