import React from "react";

import { AlertBlock as Value } from "@reactivated";

import { RichTextBlock } from "@thelabnyc/thelabui/src/components/RichText";

import { BackgroundStyle } from "../BackgroundStyle";

import styles from "./index.module.scss";

type Props = React.PropsWithChildren<{
    /**
     * Streamfield Block value
     */
    value: Value;
    className?: string;
}>;

export const AlertBlock = (props: Props) => {
    return (
        <section className={styles.root}>
            <BackgroundStyle
                style={
                    props.value.background_style
                        ? props.value.background_style
                        : "lobster"
                }
            >
                <RichTextBlock
                    value={props.value.content ? props.value.content : ""}
                />
            </BackgroundStyle>
        </section>
    );
};
