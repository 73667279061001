import React from "react";

import styles from "./Form.module.scss";

export const Confirmation = () => (
    <div className={styles.container}>
        <h1 className={styles.heading}>
            Your email unsubscribe has been submitted!
        </h1>
        <p className={styles.subheading}>
            We will work on making this update as quickly as possible. Please
            allow up to 5 days for changes to take effect.
        </p>
        <p>
            If you change your mind, you can sign-up for deals and offers
            anytime. For now, why not head back to the <a href="/">homepage</a>.
        </p>
    </div>
);
