import React from "react";

import { templates } from "@reactivated";

import { BackgroundStyle } from "../components/BackgroundStyle";
import { PageContentContainer } from "../components/PageContentContainer";
import { PageSkeleton } from "../components/PageSkeleton";
import { PressRelease } from "../components/PressRelease";

export const Template = (props: templates.PressReleasePageTemplate) => (
    <React.StrictMode>
        <PageSkeleton {...props.page}>
            <BackgroundStyle style="wood-texture">
                <PageContentContainer>
                    <PressRelease page={props.page} />
                </PageContentContainer>
            </BackgroundStyle>
        </PageSkeleton>
    </React.StrictMode>
);
