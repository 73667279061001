import React from "react";

import { templates } from "@reactivated";

import { LocatorMap } from "../components/LocatorMap";
import { PageSkeleton } from "../components/PageSkeleton";
import { DEFAULT_CONTEXT } from "../utils/testing";

export const Template = (props: templates.LocationsPageMapTemplate) => (
    <React.StrictMode>
        <PageSkeleton {...props.page}>
            <LocatorMap
                googleMapsApiKey={DEFAULT_CONTEXT.GOOGLE_MAPS_API_KEY}
            />
        </PageSkeleton>
    </React.StrictMode>
);
