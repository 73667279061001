import * as Accordion from "@radix-ui/react-accordion";
import React from "react";

import {
    AccordionFeatureBlock as Value,
    _AccordionFeature,
} from "@reactivated";

import { RichTextBlock } from "@thelabnyc/thelabui/src/components/RichText";

import { AccordionContent, AccordionTrigger } from "../Accordion";

import styles from "./index.module.scss";

interface Props {
    value: Value;
}

interface IAccordionFeature {
    index: string;
    value: _AccordionFeature;
}

export const AccordionFeature = (props: IAccordionFeature) => {
    return (
        <Accordion.Item value={props.index}>
            <AccordionTrigger className={styles.trigger}>
                <RichTextBlock
                    className="rich-text"
                    value={
                        props.value.default_content
                            ? props.value.default_content
                            : ""
                    }
                />
            </AccordionTrigger>
            <AccordionContent className={styles.content}>
                <RichTextBlock
                    className="rich-text"
                    value={
                        props.value.hidden_content
                            ? props.value.hidden_content
                            : ""
                    }
                />
            </AccordionContent>
        </Accordion.Item>
    );
};

export const AccordionFeatureBlock = (props: Props) => {
    if (!props.value.accordions) {
        return null;
    }
    return (
        <section
            className={
                props.value.image
                    ? styles.containerWithImage
                    : styles.containerWithoutImage
            }
        >
            {props.value.image && (
                <img className={styles.image} src={props.value.image.url} />
            )}
            <Accordion.Root className={styles.accordions} type="multiple">
                {props.value.accordions.map((accordion, i) => {
                    return (
                        <AccordionFeature
                            key={i}
                            index={`accordion-${i}`}
                            value={accordion}
                        />
                    );
                })}
            </Accordion.Root>
        </section>
    );
};
