import React from "react";

import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import { Location, Weekday, WeekdayInt, check } from "../../utils/models";

import styles from "./TodaysHours.module.scss";

const getCurrentWeekday = (): Weekday => {
    const now = new Date();
    const day = now.getDay();
    return check(WeekdayInt.decode(day));
};

export const TodaysHours = (props: {
    className?: string;
    location: Location;
}) => {
    const today = getCurrentWeekday();
    const hours = props.location.hours.find((p) => p.dayOfWeek === today);
    if (!hours) {
        return null;
    }
    return (
        <p className={concatClassNames([props.className, styles.hours])}>
            Today&apos;s Hours:&nbsp;
            {props.location.isTemporarilyClosed ? (
                <>Temporarily Closed</>
            ) : (
                <>
                    <time>{hours.open}</time>
                    &nbsp;&ndash;&nbsp;
                    <time>{hours.close}</time>
                </>
            )}
        </p>
    );
};
