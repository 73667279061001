import React from "react";

import { HeadingBlock as Value } from "@reactivated";

import { classNames } from "@thelabnyc/thelabui/src/utils/styles";

import styles from "./index.module.scss";

interface Props {
    value: Value;
}

export const HeadingBlock = (props: Props) => {
    const attrs = {
        className: classNames([
            [styles.heading, true],
            [styles.h2, props.value.size === "h2"],
            [styles.h3, props.value.size === "h3"],
            [styles.h4, props.value.size === "h4"],
        ]),
    };
    const heading = React.createElement(props.value.size || "h2", attrs, [
        props.value.heading_text,
    ]);
    const getStyleClass = (style: string | null) => {
        switch (style) {
            case "rope": {
                return styles.rope;
            }
            default:
                return undefined;
        }
    };
    return (
        <div className={getStyleClass(props.value.style)}>
            <div className={styles.alignment}>{heading}</div>
        </div>
    );
};
