import React from "react";

import { templates } from "@reactivated";

import { PageSkeleton } from "../components/PageSkeleton";

export const Template = (props: templates.FooterTemplate) => (
    <React.StrictMode>
        <PageSkeleton title={props.title} robots="noindex" />
    </React.StrictMode>
);
