import React from "react";

import { templates } from "@reactivated";

import { Address } from "../Address";
import { PhoneNumber } from "../LocatorMap/PhoneNumber";
import { OrderNowButton } from "../OrderNowButton";
import { DirectionsLink } from "./DirectionsLink";
import { Hours } from "./Hours";

import styles from "./index.module.scss";

export const LocationPage = (
    props: templates.LocationsPageLocationTemplate,
) => {
    if (!props.location) {
        return null;
    }
    return (
        <section className={styles.container}>
            <div>
                <h1 className={styles.heading}>
                    Red Lobster
                    <br />
                    {props.location.city}, {props.location.state}
                </h1>
                <Address className={styles.address} location={props.location} />
                <DirectionsLink location={props.location} />

                <div className={styles.mobileMap}>
                    <img src={props.location.map_image_url_mobile} />
                    <a
                        className={styles.findLocationLink}
                        href={props.page.url}
                    >
                        Find a different Red Lobster
                    </a>
                </div>

                <h2 className={styles.contactUs}>Contact Us</h2>
                <PhoneNumber
                    className={styles.phoneNumber}
                    phoneNumber={
                        props.location.phone
                            ? props.location.phone.national
                            : ""
                    }
                />
                <OrderNowButton
                    countryRootURL={props.location.countryRootURL}
                    rlid={props.location.rlid}
                    hasOnlineOrdering={props.location.has_online_ordering}
                />
                <Hours hours={props.location.hours} />
            </div>

            <div>
                <img src={props.location.map_image_url_desktop} />
                <a className={styles.findLocationLink} href={props.page.url}>
                    Find a different Red Lobster
                </a>
            </div>
        </section>
    );
};
