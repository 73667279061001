import IframeResizer from "iframe-resizer-react";
import React from "react";

import { IFrameBlock as Value } from "@reactivated";

import { useCurrentBreakpoint } from "../../hooks/layout";

import styles from "./index.module.scss";

interface Props {
    value: Value;
}

export const IFrameBlock = (props: Props) => {
    const layout = useCurrentBreakpoint();
    const url =
        props.value.mobile_url && layout.belowMedium
            ? props.value.mobile_url
            : props.value.url;
    if (!url) {
        return null;
    }
    return (
        <IframeResizer
            heightCalculationMethod="taggedElement"
            log={true}
            checkOrigin={false}
            src={url}
            className={styles.root}
            style={{
                width: 1,
                height: props.value.default_height || undefined,
                minWidth: "100%",
            }}
        />
    );
};
