import React, { useId, useState } from "react";

import { CmsTieredNavOrNull } from "@reactivated";

import { Svg } from "@thelabnyc/thelabui/src/components/Svg";
import { classNames } from "@thelabnyc/thelabui/src/utils/styles";
import { IntrinsicElementProps } from "@thelabnyc/thelabui/src/utils/types";

import { useCurrentBreakpoint } from "../../hooks/layout";

import styles from "./TieredNav.module.scss";

type CmsTieredNav = Exclude<CmsTieredNavOrNull, null>;
type Page = CmsTieredNav["root_page"];
type SubNav = CmsTieredNav["tier_two_links"][number];

const getCurrentPage = (
    nav: CmsTieredNav,
    currentPageURL: string,
): Page | null => {
    if (currentPageURL === nav.root_page.url) {
        return nav.root_page;
    }
    const t1Page = nav.tier_one_links.find(
        (link) => link.url === currentPageURL,
    );
    if (t1Page) {
        return t1Page;
    }
    const t2Page = nav.tier_two_links
        .map((t2) => t2.links)
        .flat()
        .find((link) => link.url === currentPageURL);
    if (t2Page) {
        return t2Page;
    }
    return null;
};

const getActiveSubNav = (
    nav: CmsTieredNav,
    currentPageURL: string,
): SubNav | null => {
    const subNav =
        nav.tier_two_links.find((t) => t.parent.url === currentPageURL) ||
        nav.tier_two_links.find((t) =>
            t.links.find((l) => l.url === currentPageURL),
        ) ||
        null;
    return subNav;
};

const getSubNavLinksForParent = (nav: CmsTieredNav, parent: Page): Page[] => {
    const subNav = nav.tier_two_links.find((t) => t.parent.id === parent.id);
    return subNav?.links || [];
};

const TierLinkWrapper = (props: React.PropsWithChildren) => (
    <li className={styles.listItem}>{props.children}</li>
);

const TierLink = (props: {
    value: Page;
    className?: string;
    isCurrentPage?: boolean;
}) => (
    <a
        className={classNames([
            [props.className, !!props.className],
            [styles.current, !!props.isCurrentPage],
        ])}
        href={props.value.url ?? undefined}
    >
        {props.value.title}
    </a>
);

const DesktopTieredNav = (props: {
    currentPageURL: string;
    nav: CmsTieredNav;
}) => {
    const subNav = getActiveSubNav(props.nav, props.currentPageURL);
    const t1PageURL = subNav?.parent.url || null;
    return (
        <nav>
            <ul className={styles.tierOne}>
                {props.nav.tier_one_links.map((link) => (
                    <TierLinkWrapper key={link.id}>
                        <TierLink
                            value={link}
                            className={styles.tierOneLink}
                            isCurrentPage={
                                link.url === props.currentPageURL ||
                                link.url === t1PageURL
                            }
                        />
                    </TierLinkWrapper>
                ))}
            </ul>
            {subNav && (
                <ul className={styles.tierTwo}>
                    {subNav.links.map((link, i) => (
                        <TierLinkWrapper key={link.id}>
                            <TierLink
                                value={link}
                                className={styles.tierTwoLink}
                                isCurrentPage={
                                    link.url === props.currentPageURL
                                }
                            />
                        </TierLinkWrapper>
                    ))}
                </ul>
            )}
        </nav>
    );
};

const AccordionToggle = (props: IntrinsicElementProps<HTMLButtonElement>) => (
    <button {...props} className={styles.accordionToggle}>
        {props.children}
        <Svg name="plus" />
    </button>
);

const MobileTierOneLink = (props: {
    currentPageURL: string;
    nav: CmsTieredNav;
    link: Page;
}) => {
    const toggleID = useId();
    const accordionID = useId();
    const [isExpanded, setIsExpanded] = useState(false);
    const t2Links = getSubNavLinksForParent(props.nav, props.link);
    return (
        <TierLinkWrapper>
            {t2Links.length > 0 ? (
                <AccordionToggle
                    id={toggleID}
                    aria-controls={accordionID}
                    aria-expanded={isExpanded}
                    onClick={() => {
                        setIsExpanded((exp) => !exp);
                    }}
                >
                    {props.link.title}
                </AccordionToggle>
            ) : (
                <TierLink
                    value={props.link}
                    className={styles.tierOneLink}
                    isCurrentPage={props.link.url === props.currentPageURL}
                />
            )}
            {t2Links.length > 0 && (
                <ul
                    className={styles.tierTwo}
                    role="region"
                    aria-labelledby={toggleID}
                    hidden={!isExpanded}
                >
                    <TierLinkWrapper>
                        <TierLink
                            value={props.link}
                            className={styles.tierTwoLink}
                            isCurrentPage={
                                props.link.url === props.currentPageURL
                            }
                        />
                    </TierLinkWrapper>
                    {t2Links.map((t2Link, i) => (
                        <TierLinkWrapper key={t2Link.id}>
                            <TierLink
                                value={t2Link}
                                className={styles.tierTwoLink}
                                isCurrentPage={
                                    t2Link.url === props.currentPageURL
                                }
                            />
                        </TierLinkWrapper>
                    ))}
                </ul>
            )}
        </TierLinkWrapper>
    );
};

const MobileTieredNav = (props: {
    currentPageURL: string;
    nav: CmsTieredNav;
}) => {
    const toggleID = useId();
    const accordionID = useId();
    const [isExpanded, setIsExpanded] = useState(false);
    const currentPage = getCurrentPage(props.nav, props.currentPageURL);
    const subNav = getActiveSubNav(props.nav, props.currentPageURL);
    const t1PageURL = subNav?.parent.url || null;
    if (!currentPage) {
        return null;
    }
    return (
        <nav>
            <AccordionToggle
                id={toggleID}
                aria-controls={accordionID}
                aria-expanded={isExpanded}
                onClick={() => {
                    setIsExpanded((exp) => !exp);
                }}
            >
                {currentPage.title}
            </AccordionToggle>
            <ul
                className={styles.tierOne}
                role="region"
                aria-labelledby={toggleID}
                hidden={!isExpanded}
            >
                {props.nav.tier_one_links.map((link) => (
                    <MobileTierOneLink key={link.id} {...props} link={link} />
                ))}
            </ul>
        </nav>
    );
};

export const TieredNav = (props: {
    currentPageURL: string;
    nav: CmsTieredNav;
}) => {
    const layout = useCurrentBreakpoint();
    if (layout.belowMedium) {
        return <MobileTieredNav {...props} />;
    }
    return <DesktopTieredNav {...props} />;
};
