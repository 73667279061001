export const injectScript = (src: string): void => {
    const script = document.createElement("script");
    script.src = src;
    document.body.appendChild(script);
};

export const richTextAnchorTargetLinkFormatting = (): void => {
    const anchorTargets = Array.from(
        document.querySelectorAll<HTMLAnchorElement>("span.anchor_link_target"),
    );
    anchorTargets.map((a) => {
        a.setAttribute("id", a.textContent || "");
    });
};
