import { Either, chain, isLeft } from "fp-ts/Either";
import { pipe } from "fp-ts/function";
import * as t from "io-ts";
import { fromNewtype } from "io-ts-types/fromNewtype";
import { PathReporter } from "io-ts/PathReporter";
import { Newtype, iso } from "newtype-ts";

// ============================================================================
// Utils
// ============================================================================
export const check = <T>(result: Either<t.Errors, T>): T => {
    if (isLeft(result)) {
        const msg = PathReporter.report(result).join("\n\n");
        console.log(msg);
        throw new Error(msg);
    }
    return result.right;
};

export const nullable = <RT extends t.Mixed>(type: RT) => {
    return t.union([t.null, type]);
};

// ============================================================================
// ID Types
// ============================================================================
export type RLID = Newtype<{ readonly RLID: unique symbol }, number>;
export const RLID = fromNewtype<RLID>(t.number);
export const isoRLID = iso<RLID>();

export type LegacyRLID = Newtype<
    { readonly LegacyRLID: unique symbol },
    string
>;
export const LegacyRLID = fromNewtype<LegacyRLID>(t.string);
export const isoLegacyRLID = iso<LegacyRLID>();

// ============================================================================
// Coordinates & Distances
// ============================================================================
export type Latitude = Newtype<{ readonly Latitude: unique symbol }, number>;
export const Latitude = fromNewtype<Latitude>(t.number);
export const isoLatitude = iso<Latitude>();

export type Longitude = Newtype<{ readonly Longitude: unique symbol }, number>;
export const Longitude = fromNewtype<Longitude>(t.number);
export const isoLongitude = iso<Longitude>();

export type Meters = Newtype<{ readonly Meters: unique symbol }, number>;
export const Meters = fromNewtype<Meters>(t.number);
export const isoMeters = iso<Meters>();

export type Miles = Newtype<{ readonly Miles: unique symbol }, number>;
export const Miles = fromNewtype<Miles>(t.number);
export const isoMiles = iso<Miles>();

export const LatLng = t.interface({
    lat: Latitude,
    lng: Longitude,
});
export type LatLng = t.TypeOf<typeof LatLng>;

export const fromGoogleLatLng = (point: google.maps.LatLng): LatLng => {
    return {
        lat: isoLatitude.wrap(point.lat()),
        lng: isoLongitude.wrap(point.lng()),
    };
};

export const toGoogleLatLng = (point: LatLng): google.maps.LatLng => {
    return new google.maps.LatLng({
        lat: isoLatitude.unwrap(point.lat),
        lng: isoLongitude.unwrap(point.lng),
    });
};

// ============================================================================
// Days / Hours
// ============================================================================
export enum Weekday {
    SUNDAY = 0,
    MONDAY = 1,
    TUESDAY = 2,
    WEDNESDAY = 3,
    THURSDAY = 4,
    FRIDAY = 5,
    SATURDAY = 6,
}

/* eslint-disable @typescript-eslint/no-unsafe-enum-comparison */
export type WeekdayIntC = t.Type<Weekday, number, unknown>;
export const WeekdayInt: WeekdayIntC = new t.Type<Weekday, number, unknown>(
    "WeekdayInt",
    (value): value is Weekday => {
        return (
            t.number.is(value) &&
            value >= Weekday.SUNDAY &&
            value <= Weekday.SATURDAY
        );
    },
    (input, context) => {
        return pipe(
            t.number.validate(input, context),
            chain((value) => {
                return value >= Weekday.SUNDAY && value <= Weekday.SATURDAY
                    ? t.success(value)
                    : t.failure(input, context);
            }),
        );
    },
    t.identity,
);
/* eslint-enable @typescript-eslint/no-unsafe-enum-comparison */

export const HourPeriod = t.interface({
    dayOfWeek: WeekdayInt,
    open: t.string,
    close: t.string,
});
export type HourPeriod = t.TypeOf<typeof HourPeriod>;

// ============================================================================
// Restaurant Locations
// ============================================================================
export const Location = t.interface({
    // IDs
    rlid: RLID,
    restaurantNumber: LegacyRLID,
    // Address
    address1: t.string,
    address2: nullable(t.string),
    city: t.string,
    state: t.string,
    country: t.string,
    countryRootURL: nullable(t.string),
    zip: t.string,
    // Coords
    longitude: Longitude,
    latitude: Latitude,
    // Hours
    hours: t.array(HourPeriod),
    curbsideHours: t.array(HourPeriod),
    deliveryHours: t.array(HourPeriod),
    // Contact Info
    phone: t.string,
    // Misc Metadata & Flags
    hasCurbside: t.boolean,
    hasDelivery: t.boolean,
    hasOnlineOrdering: t.boolean,
    hasTakeOut: t.boolean,
    isDiningRoomOpened: t.boolean,
    isTemporarilyClosed: t.boolean,
    localPageURL: nullable(t.string),
    outsideDining: t.boolean,
    isReservationsEnabled: t.boolean,
    // Waitlist
    estimatedWaitTime: nullable(t.string),
    waitListMaxPartySize: nullable(t.number),
    waitListReady: nullable(t.boolean),
    // Deprecated fields
    // hasRewardsProgram: t.boolean,
    // inRestaurantPartyEmail: t.string,
    // inRestaurantPartyContactName: t.boolean,
    // shouldShowSodiumDisclaimer: t.boolean,
});
export type Location = t.TypeOf<typeof Location>;

export const LocationProximity = t.interface({
    distance: Miles,
    location: Location,
});
export type LocationProximity = t.TypeOf<typeof LocationProximity>;

export const LocationQueryResponse = t.interface({
    totalLocations: t.number,
    locations: t.array(LocationProximity),
    currentLocation: t.null,
});
export type LocationQueryResponse = t.TypeOf<typeof LocationQueryResponse>;

// ============================================================================
// Email
// ============================================================================

export const UnsubscribeResponse = t.interface({
    isValid: t.boolean,
    errors: t.array(t.string),
});
export type UnsubscribeResponse = t.TypeOf<typeof UnsubscribeResponse>;
