import {
    format as formatDate,
    formatISO as formatISODate,
    parseISO as parseISODate,
} from "date-fns";
import React from "react";

import { templates } from "@reactivated";

import { RichTextBlock } from "@thelabnyc/thelabui/src/components/RichText";

import styles from "./index.module.scss";

type PressReleasePage = templates.PressReleasePageTemplate["page"];

export const PressRelease = (props: { page: PressReleasePage }) => {
    const pubDate = parseISODate(props.page.date);
    const isoPubDate = formatISODate(pubDate);
    return (
        <article
            className={styles.container}
            itemScope={true}
            itemType="http://schema.org/Article"
        >
            <h1 className={styles.header}>{props.page.title}</h1>
            <p className={styles.pubDate}>
                <time
                    dateTime={isoPubDate}
                    content={isoPubDate}
                    itemProp="datePublished"
                >
                    {formatDate(pubDate, "M.d.yy")}
                </time>
            </p>
            <RichTextBlock className="rich-text" value={props.page.copy.html} />

            {props.page.press_section && (
                <p className={styles.articleNav}>
                    <a
                        className={styles.pressSectionLink}
                        href={props.page.press_section.url}
                    >
                        {`Back to ${props.page.press_section?.title}`}
                    </a>
                </p>
            )}
        </article>
    );
};
