import React, { useEffect } from "react";

import { Widget as DefaultWidget, FieldHandler } from "@reactivated";

import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import { injectScript } from "../../utils/dom";

import styles from "./index.module.scss";

const ReCaptchaV2Checkbox = (props: {
    recaptchaDomain: string;
    apiParams: string;
    id: string;
    sitekey: string;
    size: string;
    widgetUUID: string;
}) => {
    useEffect(() => {
        const apiParams = props.apiParams ? `?${props.apiParams}` : "";
        injectScript(
            `https://${props.recaptchaDomain}/recaptcha/api.js${apiParams}`,
        );
    }, []);

    return (
        <div
            className="g-recaptcha"
            id={props.id}
            data-sitekey={props.sitekey}
            data-size={props.size}
            data-widget-uuid={props.widgetUUID}
        />
    );
};

const Widget = (props: { field: FieldHandler; choices?: string[] }) => {
    const field = props.field;

    if (field.tag == "phonenumber_field.widgets.RegionalPhoneNumberWidget") {
        return (
            <input
                type="text"
                name={field.name}
                value={field.value ?? ""}
                onChange={(event) => field.handler(event.currentTarget.value)}
            />
        );
    }

    if (field.tag == "server.cms.forms.ReCaptchaV2Checkbox") {
        return (
            <ReCaptchaV2Checkbox
                recaptchaDomain={field.widget.recaptcha_domain}
                apiParams={field.widget.api_params}
                id={field.widget.attrs.id}
                sitekey={field.widget.public_key}
                size={field.widget.size}
                widgetUUID={field.widget.widget_uuid}
            />
        );
    }

    if (field.tag == "django.forms.widgets.TextInput" && props.choices) {
        return (
            <select
                name={field.name}
                value={field.value ?? ""}
                onChange={(event) => field.handler(event.currentTarget.value)}
            >
                {props.choices.map((choice) => (
                    <option key={choice} value={choice}>
                        {choice}
                    </option>
                ))}
            </select>
        );
    }
    return <DefaultWidget field={field} />;
};

type FieldWrapperOptions = {
    label: string | React.ReactNode;
    isCheckbox?: boolean;
    hideLabel?: boolean;
    wrapperClassName?: string;
    error?: string | null;
};

export const FieldWrapper = (
    props: React.PropsWithChildren<FieldWrapperOptions>,
) => (
    <div className={concatClassNames([styles.wrapper, props.wrapperClassName])}>
        <label
            className={concatClassNames([
                styles.label,
                props.isCheckbox ? styles.checkbox : null,
            ])}
        >
            {!props.hideLabel && !props.isCheckbox && (
                <div className={styles.labelText}>{props.label}</div>
            )}

            <div className={styles.widgetWrapper}>{props.children}</div>

            {!props.hideLabel && props.isCheckbox && (
                <div className={styles.label}>{props.label}</div>
            )}
        </label>
        {props.error != null && (
            <div className={styles.error}>{props.error}</div>
        )}
    </div>
);

export const FormField = (
    props: Pick<FieldWrapperOptions, "hideLabel" | "wrapperClassName"> & {
        field: FieldHandler;
        label?: string | React.ReactNode;
        choices?: string[];
    },
) => (
    <FieldWrapper
        label={props.label || props.field.label}
        isCheckbox={props.field.tag === "django.forms.widgets.CheckboxInput"}
        hideLabel={props.hideLabel}
        wrapperClassName={props.wrapperClassName}
        error={props.field.error}
    >
        <Widget field={props.field} choices={props.choices} />
    </FieldWrapper>
);
