import { GoogleMap, InfoWindow, Marker } from "@react-google-maps/api";
import React, { useRef } from "react";

import mapPinImg from "../../../static/img/map-pin.png";
import {
    LocationProximity,
    RLID,
    isoRLID,
    toGoogleLatLng,
} from "../../utils/models";
import { getGlyph } from "./IndexGlyph";
import { InfoWindowContent } from "./InfoWindowContent";

const containerStyle = {
    width: "100%",
    height: "100%",
};

const mapStyles = [
    {
        featureType: "administrative",
        elementType: "labels.text.fill",
        stylers: [
            {
                color: "#444444",
            },
        ],
    },
    {
        featureType: "landscape",
        elementType: "all",
        stylers: [
            {
                color: "#f2f2f2",
            },
        ],
    },
    {
        featureType: "poi",
        elementType: "all",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "road",
        elementType: "all",
        stylers: [
            {
                saturation: -100,
            },
            {
                lightness: 45,
            },
        ],
    },
    {
        featureType: "road.highway",
        elementType: "all",
        stylers: [
            {
                visibility: "simplified",
            },
        ],
    },
    {
        featureType: "road.arterial",
        elementType: "labels.icon",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "transit",
        elementType: "all",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "water",
        elementType: "all",
        stylers: [
            {
                color: "#dfcbca",
            },
            {
                visibility: "on",
            },
        ],
    },
];

export const MarkerMap = (props: {
    locations: LocationProximity[];
    focusedLocationID: RLID | null;
    setFocusedLocationID: (rlid: RLID | null) => void;
}) => {
    const mapRef = useRef<google.maps.Map | null>(null);
    const focusedMarkerRefs = useRef(new Map<RLID, google.maps.Marker>());
    const infoWindowRef = useRef<google.maps.InfoWindow | null>(null);

    const firstLocation =
        props.locations.length >= 1 ? props.locations[0] : null;
    const focusedLocation =
        props.locations.find((locProx) => {
            return locProx.location.rlid === props.focusedLocationID;
        }) || firstLocation;
    const focusedMarker = focusedLocation
        ? focusedMarkerRefs.current.get(focusedLocation.location.rlid)
        : null;

    // Automatically pan the map to the focused location
    if (focusedLocation && mapRef.current) {
        mapRef.current.panTo(
            toGoogleLatLng({
                lat: focusedLocation.location.latitude,
                lng: focusedLocation.location.longitude,
            }),
        );
    }
    // Move the info to the currently focused marker
    if (infoWindowRef.current && focusedMarker) {
        infoWindowRef.current.close();
        infoWindowRef.current.open({
            anchor: focusedMarker,
        });
    }

    return (
        <GoogleMap
            mapContainerStyle={containerStyle}
            zoom={10}
            onLoad={(map) => {
                mapRef.current = map;
                map.set("styles", mapStyles);
            }}
        >
            {props.locations.map(({ location }, index) => (
                <Marker
                    key={`${isoRLID.unwrap(location.rlid)}`}
                    icon={{
                        url: mapPinImg,
                        labelOrigin: new google.maps.Point(18, 20),
                    }}
                    label={{
                        text: getGlyph(index),
                        color: "black",
                        fontWeight: "bold",
                        fontSize: "20px",
                        fontFamily:
                            '"Nobel Regular", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif',
                    }}
                    position={toGoogleLatLng({
                        lat: location.latitude,
                        lng: location.longitude,
                    })}
                    onLoad={(marker) => {
                        focusedMarkerRefs.current.set(location.rlid, marker);
                    }}
                    onUnmount={() => {
                        focusedMarkerRefs.current.delete(location.rlid);
                    }}
                    onClick={() => {
                        props.setFocusedLocationID(location.rlid);
                    }}
                />
            ))}

            {focusedLocation && focusedMarker && (
                <InfoWindow
                    anchor={focusedMarker}
                    onLoad={(infoWindow) => {
                        infoWindowRef.current = infoWindow;
                    }}
                >
                    <InfoWindowContent
                        index={props.locations.indexOf(focusedLocation)}
                        {...focusedLocation}
                    />
                </InfoWindow>
            )}
        </GoogleMap>
    );
};
