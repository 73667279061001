// All changes must also be made to the _layout.scss file in this same directory.
export const pageMaxWidth = 1140;

export const totalColumns = 12;

export const mediumLine = 840;
export const largeLine = 1440;

export const belowMedium = `(min-width: 0px) and (max-width: ${mediumLine}px)`;
export const aboveMedium = `(min-width: ${mediumLine + 1}px)`;
export const belowLargeLine = `(max-width: ${largeLine}px)`;
export const aboveLargeLine = `(min-width: ${largeLine + 1}px)`;

export const gridGap = 26;
