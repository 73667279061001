import * as Accordion from "@radix-ui/react-accordion";
import React from "react";
import { t } from "ttag";

import { templates } from "@reactivated";

import locationHeroImage from "../../static/img/rl-locations-page-header-image.png";
import { AccordionContent, AccordionTrigger } from "../components/Accordion";
import { BackgroundStyle } from "../components/BackgroundStyle";
import { HeadingBlock } from "../components/HeadingBlock";
import { Hero } from "../components/HeroBlock";
import { PageSkeleton } from "../components/PageSkeleton";

import styles from "../styles/templates/LocationsPageStateListTemplate.module.scss";

export const Template = (props: templates.LocationsPageStateListTemplate) => {
    return (
        <React.StrictMode>
            <PageSkeleton {...props.page}>
                <BackgroundStyle style="wood-texture">
                    <Hero
                        imageURL={locationHeroImage}
                        imageAlignment="align-center"
                        textStyle="light"
                        title={t`All ${props.page.country.printable_name} Locations`}
                        subtitle={t`Select a state to find a local Red Lobster.`}
                    />
                    <section>
                        <div className={styles.navLinks}>
                            {props.other_directories.map((page) => (
                                <a
                                    key={page.id}
                                    className={styles.otherCountryLink}
                                    href={page.url}
                                >
                                    See {page.country.printable_name} Locations
                                </a>
                            ))}
                        </div>
                        <HeadingBlock
                            value={{
                                heading_text: props.page.country.printable_name,
                                size: "h2",
                                style: "rope",
                            }}
                        />
                        <Accordion.Root
                            className={styles.container}
                            type="multiple"
                        >
                            {props.states.map((state) => (
                                <Accordion.Item
                                    key={state.slug}
                                    value={state.slug}
                                >
                                    <AccordionTrigger className={styles.state}>
                                        {state.name}
                                    </AccordionTrigger>
                                    <AccordionContent
                                        className={styles.content}
                                    >
                                        <ul className={styles.list}>
                                            {state.cities.map((city) => (
                                                <li key={city.slug}>
                                                    <a
                                                        className={styles.city}
                                                        href={city.url}
                                                    >
                                                        {city.name}
                                                    </a>
                                                </li>
                                            ))}
                                        </ul>
                                    </AccordionContent>
                                </Accordion.Item>
                            ))}
                        </Accordion.Root>
                    </section>
                </BackgroundStyle>
            </PageSkeleton>
        </React.StrictMode>
    );
};
