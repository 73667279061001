import * as Accordion from "@radix-ui/react-accordion";
import React from "react";

export const AccordionTrigger = React.forwardRef(
    (
        {
            className,
            children,
            ...props
        }: React.PropsWithChildren<{
            className?: string;
        }>,
        forwardedRef: React.Ref<HTMLButtonElement>,
    ) => (
        <Accordion.Header>
            <Accordion.Trigger
                className={className}
                {...props}
                ref={forwardedRef}
            >
                {children}
            </Accordion.Trigger>
        </Accordion.Header>
    ),
);

export const AccordionContent = React.forwardRef(
    (
        {
            className,
            children,
            ...props
        }: React.PropsWithChildren<{
            className?: string;
        }>,
        forwardedRef: React.Ref<HTMLDivElement>,
    ) => (
        <Accordion.Content className={className} {...props} ref={forwardedRef}>
            <div className="AccordionContentText">{children}</div>
        </Accordion.Content>
    ),
);
