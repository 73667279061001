import React from "react";

import { PageContentContainer } from "../PageContentContainer";

import styles from "./index.module.scss";

interface Props {
    style: string;
    children: React.ReactNode;
}

const getStyleClass = (style: string) => {
    switch (style) {
        case "left-aligned": {
            return styles.leftAligned;
        }
        default:
            return undefined;
    }
};

export const Layout = (props: Props) => {
    if (props.style === "centered") {
        return <PageContentContainer>{props.children}</PageContentContainer>;
    }
    return <div className={getStyleClass(props.style)}>{props.children}</div>;
};
