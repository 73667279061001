import React, { useState } from "react";

import { CouponBlock as Value } from "@reactivated";

import styles from "./index.module.scss";

interface Props {
    value: Value;
}

export const CouponBlock = (props: Props) => {
    const [hidden, setHidden] = useState(true);

    if (!props.value.coupon) {
        return null;
    }

    return (
        <section className={styles.container}>
            <div className={styles.wrapper} aria-hidden={!hidden}>
                <div className={styles.imageWrapper}>
                    {props.value.expires_on && (
                        <span className={styles.expiresOn}>
                            {props.value.expires_on}
                        </span>
                    )}
                    <img
                        className={styles.image}
                        src={props.value.coupon.url}
                    />
                </div>
                <button
                    className="button"
                    onClick={() => {
                        window.print();
                        setHidden(false);
                    }}
                >
                    Print Coupon
                </button>
            </div>
            <div className={styles.wrapper} aria-hidden={hidden}>
                <h2 className={styles.thankYouHeading}>
                    You&apos;re All Set. Enjoy Your Coupon!
                </h2>
                <p>We look forward to seeing you soon.</p>
            </div>
        </section>
    );
};
