import React from "react";

import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import styles from "./IndexGlyph.module.scss";

const GLYPHS = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

export const getGlyph = (index: number): string => {
    const boundedIdx = index % GLYPHS.length;
    return GLYPHS[boundedIdx];
};

export const IndexGlyph = (props: { className?: string; index: number }) => {
    return (
        <p className={concatClassNames([props.className, styles.glyph])}>
            {getGlyph(props.index)}
        </p>
    );
};
