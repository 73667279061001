import React from "react";

import { FullWidthMediaWithCTABlock as Value } from "@reactivated";

import { useCurrentBreakpoint } from "../../hooks/layout";
import { FullWidthMediaBlock } from "../FullWidthMedia";

import styles from "./index.module.scss";

interface Props {
    value: Value;
}

export const FullWidthMediaWithCTABlock = (props: Props) => {
    const layout = useCurrentBreakpoint();
    if (!props.value.media) {
        return null;
    }
    let media = props.value.media;
    if (
        layout.belowMedium &&
        props.value.mobile_media &&
        props.value.mobile_media.length !== 0
    ) {
        media = props.value.mobile_media;
    }
    let maxHeight = "auto";
    if (props.value.max_height && layout.aboveMedium) {
        maxHeight = props.value.max_height;
    }
    return (
        <section className={styles.root}>
            <FullWidthMediaBlock maxHeight={maxHeight} value={media} />
            {props.value.cta && (
                <a
                    href={props.value.cta.url ?? undefined}
                    className={styles.button}
                >
                    {props.value.cta.display_name ?? props.value.cta.url}
                </a>
            )}
        </section>
    );
};
