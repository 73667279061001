import React from "react";

import { AlignedRichTextBlock as Value } from "@reactivated";

import { AlignedRichTextBlock as LabUIAlignedRichTextBlock } from "@thelabnyc/thelabui/src/components/AlignedRichTextBlock";
import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import { Layout } from "../Layout";

import styles from "./index.module.scss";

type Props = React.PropsWithChildren<{
    /**
     * Streamfield Block value
     */
    value: Value;
    className?: string;
}>;

export const AlignedRichTextBlock = (props: Props) => {
    return (
        <Layout style={props.value.layout ? props.value.layout : ""}>
            <LabUIAlignedRichTextBlock
                value={props.value}
                attrs={{
                    className: concatClassNames([
                        styles.container,
                        "rich-text",
                        props.className,
                    ]),
                }}
            >
                {props.children}
            </LabUIAlignedRichTextBlock>
        </Layout>
    );
};
