import React from "react";

import styles from "./FooterLinks.module.scss";

export type Page = {
    id: number;
    url: string;
    title: string;
};

export const FooterLinks = (props: {
    faqPage: Page;
    unsubscribePage: Page;
}) => (
    <div className={styles.footerLinks}>
        <a
            target="_blank"
            rel="noreferrer"
            href={props.faqPage.url}
            className={styles.footerLink}
        >
            Browse Faq
        </a>
        <a
            target="_blank"
            rel="noreferrer"
            href={props.unsubscribePage.url}
            className={styles.footerLink}
        >
            Unsubscribe
        </a>
    </div>
);
