import React from "react";
import { t } from "ttag";

import { Location, RLID, isoRLID } from "../../utils/models";
import { APIAddress } from "../Address";
import { FieldWrapper } from "../FormField";
import { Modal, ModalRoot } from "../Modal";

import styles from "./LocationSelectModal.module.scss";

interface IProps {
    searchText: string;
    locations: Location[];
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    setSearchText: (searchText: string) => void;
    onSearch: () => void;
    onSelect: (rlid: RLID) => void;
    onSeeMore: () => void;
}

export const LocationSelectModal = (props: IProps) => (
    <ModalRoot open={props.isOpen} onOpenChange={props.setIsOpen}>
        <Modal contentLabel={t`Choose Your Restaurant`}>
            <div className={styles.modalContent}>
                <h2>{t`Choose Your Restaurant`}</h2>
                <form
                    onSubmit={(event) => {
                        event.preventDefault();
                        props.onSearch();
                    }}
                >
                    <FieldWrapper
                        label={t`Please select the restaurant where you had your experience or search again`}
                    >
                        <input
                            type="text"
                            value={props.searchText}
                            onChange={(event) => {
                                props.setSearchText(event.currentTarget.value);
                            }}
                        />
                        <input type="submit" value={t`SEARCH AGAIN`} />
                    </FieldWrapper>
                </form>

                <hr />

                <p>{t`Showing ${props.locations.length} results.`}</p>

                <div className={styles.resultGrid}>
                    {props.locations.map((location) => (
                        <button
                            key={`${isoRLID.unwrap(location.rlid)}`}
                            onClick={() => {
                                props.onSelect(location.rlid);
                            }}
                        >
                            <APIAddress location={location} />
                        </button>
                    ))}
                </div>
            </div>
        </Modal>
    </ModalRoot>
);
