import React from "react";
import { t } from "ttag";

import { Location, RLID, isoRLID } from "../../utils/models";

import styles from "./index.module.scss";

const openWaitListModal = (rlid: RLID) => {
    document.dispatchEvent(
        new CustomEvent("open-waitlist", {
            detail: isoRLID.unwrap(rlid),
        }),
    );
};

const openReservationModal = (rlid: RLID) => {
    document.dispatchEvent(
        new CustomEvent("open-reservation-panel", {
            detail: isoRLID.unwrap(rlid),
        }),
    );
};

export const MakeReservationButton = (props: { location: Location }) => {
    if (props.location.isReservationsEnabled) {
        return (
            <button
                className={styles.button}
                onClick={(e) => {
                    openReservationModal(props.location.rlid);
                }}
            >
                {t`Reserve a Table`}
            </button>
        );
    }

    // Only show the button unless the waitlist is "ready". This
    // bool indicates that the location is open and seating customers.
    if (props.location.waitListReady) {
        return (
            <button
                className={styles.button}
                onClick={(e) => {
                    openWaitListModal(props.location.rlid);
                }}
            >
                {t`Join the wait list`}
            </button>
        );
    }

    return null;
};
