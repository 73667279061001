import React from "react";

import { templates } from "@reactivated";

import { ListItem } from "./ListItem";
import { Pagination } from "./Pagination";

import styles from "./index.module.scss";

export const PressReleaseList = (props: {
    page: templates.PressReleaseListPageTemplate["page"];
    articles: templates.PressReleaseListPageTemplate["press_releases"];
}) => (
    <div>
        <div className={styles.articles}>
            {props.articles.articles.map((article) => (
                <ListItem key={article.id} article={article} />
            ))}
        </div>
        <Pagination {...props} />
        {props.page.press_section && (
            <p className={styles.backToPress}>
                <a href={props.page.press_section.url}>
                    <svg viewBox="0 0 320 512">
                        <path d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
                    </svg>
                    &nbsp;
                    {`Back to ${props.page.press_section?.title}`}
                </a>
            </p>
        )}
    </div>
);
