import * as Tabs from "@radix-ui/react-tabs";
import React from "react";

import { TabbedFeaturesBlock as Value } from "@reactivated";

import { useCurrentBreakpoint } from "../../hooks/layout";
import { AlignedRichTextBlock } from "../AlignedRichTextBlock";
import { TwoColumnFeatureBlock } from "../TwoColumnFeature";

import styles from "./index.module.scss";

interface Props {
    value: Value;
}

export const TabbedFeaturesBlock = (props: Props) => {
    const layout = useCurrentBreakpoint();
    if (!props.value.tabs) {
        return null;
    }
    return (
        <section className={styles.container}>
            {props.value.header_content && (
                <AlignedRichTextBlock value={props.value.header_content} />
            )}
            <Tabs.Root
                defaultValue="tab-0"
                orientation={layout.belowMedium ? "vertical" : "horizontal"}
            >
                <Tabs.List className={styles.tabList}>
                    {props.value.tabs.map((tab, i) => {
                        return (
                            <Tabs.Trigger
                                key={i}
                                className={styles.tab}
                                value={`tab-${i}`}
                            >
                                {tab.tab_name}
                            </Tabs.Trigger>
                        );
                    })}
                </Tabs.List>
                {props.value.tabs.map((tab, i) => {
                    if (!tab.content) {
                        return null;
                    }
                    return (
                        <Tabs.Content key={i} value={`tab-${i}`}>
                            {tab.content.map((content, i) => {
                                return (
                                    <TwoColumnFeatureBlock
                                        key={i}
                                        value={content}
                                    />
                                );
                            })}
                        </Tabs.Content>
                    );
                })}
            </Tabs.Root>
        </section>
    );
};
