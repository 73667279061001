import { Libraries, useJsApiLoader } from "@react-google-maps/api";
import React, { useState } from "react";
import { t } from "ttag";

import {
    useNearbyRetailLocations,
    useUserLocation,
} from "../../hooks/location";
import { RLID } from "../../utils/models";
import { LocationList } from "./LocationList";
import { MarkerMap } from "./MarkerMap";
import { UpdateLocationForm } from "./UpdateLocationForm";

import styles from "./index.module.scss";

const GOOGLE_LIBRARIES: Libraries = ["places", "geometry"];

export const LocatorMap = (props: { googleMapsApiKey: string }) => {
    useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: props.googleMapsApiKey,
        libraries: GOOGLE_LIBRARIES,
    });
    const { userLocation, setEnteredLocation } = useUserLocation();
    const [focusedLocationID, setFocusedLocationID] = useState<RLID | null>(
        null,
    );
    const queryResp = useNearbyRetailLocations(userLocation);
    const locations = queryResp?.locations || [];

    return (
        <div className={styles.container}>
            <div className={styles.column1}>
                <UpdateLocationForm onLocationUpdate={setEnteredLocation} />
                {locations.length === 0 && (
                    <p className={styles.locationError}>
                        {t`Please enter a valid city, state, or ZIP code.`}
                    </p>
                )}
                <LocationList
                    locations={locations}
                    onSelect={setFocusedLocationID}
                />
            </div>
            <div className={styles.column2}>
                <MarkerMap
                    locations={locations}
                    focusedLocationID={focusedLocationID}
                    setFocusedLocationID={setFocusedLocationID}
                />
            </div>
        </div>
    );
};
