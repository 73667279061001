import React from "react";

import {
    _Content as Blocks,
    CustomBackgroundImageWrapper as Value,
} from "@reactivated";

import {
    StreamField,
    StreamFieldBlockMap,
} from "@thelabnyc/thelabui/src/components/StreamField";
import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import { useCurrentBreakpoint } from "../../hooks/layout";
import { AccordionFeatureBlock } from "../AccordionFeature";
import { AlignedRichTextBlock } from "../AlignedRichTextBlock";
import { LinkRowBlock } from "../LinkRow";
import { TwoColumnFeatureBlock } from "../TwoColumnFeature";

import styles from "./index.module.scss";

interface Props {
    value: Value;
}

const components: StreamFieldBlockMap<Blocks> = {
    accordion_feature: AccordionFeatureBlock,
    artf: AlignedRichTextBlock,
    link_row: LinkRowBlock,
    two_column_feature: TwoColumnFeatureBlock,
};

const getStyleClass = (style: string | null) => {
    switch (style) {
        case "light": {
            return styles.textLight;
        }
        default:
            return undefined;
    }
};

export const CustomBackgroundImageWrapper = (props: Props) => {
    const viewport = useCurrentBreakpoint();
    if (!props.value.content || !props.value.background_image) {
        return null;
    }
    const backgroundImage =
        viewport.belowMedium && props.value.mobile_background_image
            ? props.value.mobile_background_image
            : props.value.background_image;
    return (
        <section
            className={concatClassNames([
                styles.root,
                getStyleClass(props.value.text_style),
            ])}
        >
            <img className={styles.backgroundImage} src={backgroundImage.url} />
            <div className={styles.wrapper}>
                <StreamField
                    components={components}
                    value={props.value.content}
                />
            </div>
        </section>
    );
};
