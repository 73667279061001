import { t } from "ttag";

type ConditionalField =
    | "visitLocation"
    | "visitDate"
    | "visitTime"
    | "orderNumber";

type ExtraFields = {
    [name in ConditionalField]?: boolean;
};

interface Topic {
    name: string;
    extraFields: ExtraFields;
}

interface Category {
    name: string;
    topics: Topic[];
}

const NO_EXTRA_FIELDS: ExtraFields = {};
const ALL_EXTRA_FIELDS: ExtraFields = {
    visitLocation: true,
    visitDate: true,
    visitTime: true,
    orderNumber: true,
};

const getTopicCategories = (): Category[] => {
    return [
        {
            name: t`My Red Lobster Rewards™`,
            topics: [
                {
                    name: t`Help With Adding Points`,
                    extraFields: NO_EXTRA_FIELDS,
                },
                {
                    name: t`Help With Using Rewards`,
                    extraFields: NO_EXTRA_FIELDS,
                },
                {
                    name: t`Question About My Points/rewards`,
                    extraFields: NO_EXTRA_FIELDS,
                },
                {
                    name: t`Help With My Profile/sign In`,
                    extraFields: NO_EXTRA_FIELDS,
                },
                {
                    name: t`Other`,
                    extraFields: NO_EXTRA_FIELDS,
                },
            ],
        },
        {
            name: t`Praise`,
            topics: [
                {
                    name: t`About Dining, To Go, Or Delivery`,
                    extraFields: ALL_EXTRA_FIELDS,
                },
                {
                    name: t`Other`,
                    extraFields: NO_EXTRA_FIELDS,
                },
            ],
        },
        {
            name: t`Suggestion`,
            topics: [
                {
                    name: t`New Location`,
                    extraFields: NO_EXTRA_FIELDS,
                },
                {
                    name: t`Menu Idea`,
                    extraFields: NO_EXTRA_FIELDS,
                },
                {
                    name: t`Other`,
                    extraFields: NO_EXTRA_FIELDS,
                },
            ],
        },
        {
            name: t`Question`,
            topics: [
                {
                    name: t`Location Information`,
                    extraFields: NO_EXTRA_FIELDS,
                },
                {
                    name: t`Menu Items/Events`,
                    extraFields: {
                        visitLocation: true,
                    },
                },
                {
                    name: t`Allergen And Nutrition`,
                    extraFields: {
                        visitLocation: true,
                    },
                },
                {
                    name: t`Donations`,
                    extraFields: NO_EXTRA_FIELDS,
                },
                {
                    name: t`Gift Cards`,
                    extraFields: NO_EXTRA_FIELDS,
                },
                {
                    name: t`Employment/Human Resources`,
                    extraFields: NO_EXTRA_FIELDS,
                },
                {
                    name: t`Fresh Catch News`,
                    extraFields: NO_EXTRA_FIELDS,
                },
                {
                    name: t`Other`,
                    extraFields: NO_EXTRA_FIELDS,
                },
            ],
        },
        {
            name: t`Issue or Concern?`,
            topics: [
                {
                    name: t`About Dining In, To Go, Or Delivery`,
                    extraFields: ALL_EXTRA_FIELDS,
                },
                {
                    name: t`Other`,
                    extraFields: NO_EXTRA_FIELDS,
                },
            ],
        },
    ];
};

export const getCategoryChoices = (): string[] => {
    const cats = getTopicCategories();
    return [""].concat(cats.map((cat) => cat.name));
};

export const getTopicChoices = (category: string | null): string[] => {
    const cats = getTopicCategories();
    const cat = cats.find((cat) => cat.name === category);
    const topics = cat ? cat.topics.map((topic) => topic.name) : [];
    return [""].concat(topics);
};

export const getExtraFields = (
    categoryName: string | null,
    topicName: string | null,
): ExtraFields => {
    const cats = getTopicCategories();
    const cat = cats.find((cat) => cat.name === categoryName);
    if (!cat) {
        return NO_EXTRA_FIELDS;
    }
    const topic = cat.topics.find((t) => t.name === topicName);
    if (!topic) {
        return NO_EXTRA_FIELDS;
    }
    return topic.extraFields;
};
