import { BackgroundStyle } from "@client/components/BackgroundStyle";
import React from "react";

import { templates } from "@reactivated";

import { LocationPage } from "../components/LocationPage";
import { PageSkeleton } from "../components/PageSkeleton";

export const Template = (props: templates.LocationsPageLocationTemplate) => (
    <React.StrictMode>
        <PageSkeleton {...props.page}>
            <BackgroundStyle style="wood-texture">
                <LocationPage {...props} />
            </BackgroundStyle>
        </PageSkeleton>
    </React.StrictMode>
);
