import React from "react";

import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import { Location } from "../../utils/models";

import styles from "./CurrentWaitTime.module.scss";

export const CurrentWaitTime = (props: {
    className?: string;
    location: Location;
}) => {
    if (!props.location.waitListReady) {
        return null;
    }
    return (
        <p className={concatClassNames([props.className, styles.waitTime])}>
            Current wait: <strong>{props.location.estimatedWaitTime}</strong>
        </p>
    );
};
