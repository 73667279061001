import React from "react";

import { ContentGridBlock as Value, _GridItem } from "@reactivated";

import { ImageChooserBlock } from "@thelabnyc/thelabui/src/components/ImageChooserBlock";
import { RichTextBlock } from "@thelabnyc/thelabui/src/components/RichText";

import { PageContentContainer } from "../PageContentContainer";

import styles from "./index.module.scss";

interface Props {
    value: Value;
}

interface IGridItem {
    value: _GridItem;
}

export const ContentGridItem = (props: IGridItem) => {
    if (!props.value.content) {
        return null;
    }
    return (
        <div>
            {props.value.image && (
                <ImageChooserBlock value={props.value.image} />
            )}
            <RichTextBlock className="rich-text" value={props.value.content} />
        </div>
    );
};

export const ContentGridBlock = (props: Props) => {
    if (!props.value.items) {
        return null;
    }
    return (
        <PageContentContainer>
            <div className={styles.container}>
                {props.value.items.map((item, i) => {
                    return <ContentGridItem key={i} value={item} />;
                })}
            </div>
        </PageContentContainer>
    );
};
