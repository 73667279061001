import React from "react";

import { SocialMediaIconList as Value } from "@reactivated";

import { SocialMediaIconList } from "@thelabnyc/thelabui/src/components/SocialMediaIcon";

import styles from "./SocialMediaRow.module.scss";

export const SocialMediaRow = (props: { value: Value }) => (
    <div className={styles.row}>
        <SocialMediaIconList value={props.value} />
    </div>
);
