import React from "react";

import { Context, TieredNavBlock as Value } from "@reactivated";

import { TieredNav } from "./TieredNav";

export const TieredNavBlock = (props: { value: Value }) => {
    const context = React.useContext(Context);
    if (!props.value.tiered_nav) {
        return null;
    }
    return (
        <TieredNav
            currentPageURL={context.request.url}
            nav={props.value.tiered_nav}
        />
    );
};
