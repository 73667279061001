import * as Accordion from "@radix-ui/react-accordion";
import React from "react";

import { FAQsBlock as Value, _FAQ } from "@reactivated";

import { RichTextBlock } from "@thelabnyc/thelabui/src/components/RichText";
import { classNames } from "@thelabnyc/thelabui/src/utils/styles";

import { AccordionContent, AccordionTrigger } from "../Accordion";
import { PageContentContainer } from "../PageContentContainer";

import styles from "./index.module.scss";

interface Props {
    value: Value;
}

interface IFAQ {
    index: string;
    faq: _FAQ;
}

export const FAQ = (props: IFAQ) => {
    return (
        <Accordion.Item value={props.index}>
            <AccordionTrigger className={styles.question}>
                {props.faq.question}
            </AccordionTrigger>
            <AccordionContent className={styles.answer}>
                <RichTextBlock
                    className="rich-text"
                    value={props.faq.answer ? props.faq.answer : ""}
                />
            </AccordionContent>
        </Accordion.Item>
    );
};

export const FAQsBlock = (props: Props) => {
    if (!props.value.faqs) {
        return null;
    }
    const gridClasses = classNames([
        [styles.list, true],
        [styles.oneColumn, props.value.layout === "one-column"],
        [styles.twoColumn, props.value.layout === "two-column"],
    ]);
    return (
        <PageContentContainer>
            <section className={styles.wrapper}>
                <RichTextBlock
                    className="rich-text"
                    value={props.value.heading ? props.value.heading : ""}
                />
                <Accordion.Root className={gridClasses} type="multiple">
                    {props.value.faqs.map((faq, i) => {
                        return <FAQ key={i} index={`faq-${i}`} faq={faq} />;
                    })}
                </Accordion.Root>
            </section>
        </PageContentContainer>
    );
};
