import React from "react";

import styles from "./TileContainer.module.scss";

interface Props {
    aspectRatio: number;
    numSiblings: number;
    children?: React.ReactNode;
}

export const TileContainer = (props: Props) => {
    return (
        <figure
            className={styles.tileContainer}
            style={{
                flexBasis: 0,
                flexGrow: props.numSiblings > 0 ? props.aspectRatio : 1,
            }}
        >
            {props.children}
        </figure>
    );
};
