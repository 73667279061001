import React from "react";

import { templates } from "@reactivated";

import { BackgroundStyle } from "../components/BackgroundStyle";
import { PageContentContainer } from "../components/PageContentContainer";
import { PageSkeleton } from "../components/PageSkeleton";
import { PressHero } from "../components/PressHero";
import { PressReleaseList } from "../components/PressReleaseList";

export const Template = (props: templates.PressReleaseListPageTemplate) => (
    <React.StrictMode>
        <PageSkeleton {...props.page}>
            <BackgroundStyle style="wood-texture">
                <PressHero title="Press Releases" />
                <PageContentContainer>
                    <PressReleaseList
                        page={props.page}
                        articles={props.press_releases}
                    />
                </PageContentContainer>
            </BackgroundStyle>
        </PageSkeleton>
    </React.StrictMode>
);
