import React from "react";
import { t } from "ttag";

import { FieldWrapper } from "../FormField";

import styles from "./Form.module.scss";

export const Form = (props: {
    email: string;
    setEmail: (email: string) => void;
    error: string | null;
    onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
    isSubmitting: boolean;
}) => (
    <div className={styles.container}>
        <h1 className={styles.heading}>{t`Unsubscribe from our emails`}</h1>
        <p className={styles.subheading}>
            {t`If you'd rather not get offers and news, just enter your email below. You're welcome back anytime.`}
        </p>
        <form className={styles.form} onSubmit={props.onSubmit}>
            <FieldWrapper
                label={t`Email Address`}
                error={props.error}
                wrapperClassName={styles.emailField}
            >
                <input
                    type="email"
                    name="Email"
                    placeholder={t`Email`}
                    value={props.email}
                    onChange={(e) => {
                        props.setEmail(e.currentTarget.value);
                    }}
                    disabled={props.isSubmitting}
                />
            </FieldWrapper>
            <button
                type="submit"
                className={styles.submit}
                disabled={props.isSubmitting}
            >
                {t`Submit`}
            </button>
        </form>
    </div>
);
