import React from "react";

import { FooterBlock as FooterBlockValue } from "@reactivated";

import { StreamField } from "@thelabnyc/thelabui/src/components/StreamField";

import { useCurrentBreakpoint } from "../../hooks/layout";
import { FooterColumnList } from "./FooterColumnList";
import { LegalText } from "./LegalText";
import { RegionNav } from "./RegionNav";

import styles from "./index.module.scss";

// IMPORTANT: This class, .rl-custom-footer-wrapper, is VERY important. The RL
// Ecom site queries for that specific class name in order to extract the HTML
// the brand site and then inject it into their own pages. For more info
// see server.cms.views.FooterView. As well, because of how they select the
// node (using XPath, not CSS selectors), the class attribute cannot contain
// any other class names. It must exactly match `//*[@class="rl-custom-footer-wrapper"]`.
export const FooterBlock = (props: { value: FooterBlockValue }) => {
    const layout = useCurrentBreakpoint();
    return (
        <div className="rl-custom-footer-wrapper">
            <StreamField
                tagName="footer"
                attrs={{
                    className: styles.footer,
                }}
                components={{
                    site_nav: FooterColumnList,
                    region_nav: RegionNav,
                    legal_text: LegalText,
                }}
                value={props.value}
            />
            {layout.belowMedium && (
                <a className={styles.toTopLink} href="#mainContent">
                    Back to Top
                </a>
            )}
        </div>
    );
};
