import React from "react";

import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import { Location, isoLatitude, isoLongitude } from "../../utils/models";

import styles from "./GetDirectionsLink.module.scss";

export const GetDirectionsLink = (props: {
    className?: string;
    location: Location;
}) => {
    return (
        <a
            className={concatClassNames([props.className, styles.link])}
            href={`https://www.google.com/maps/dir/Current+Location/${isoLatitude.unwrap(
                props.location.latitude,
            )},${isoLongitude.unwrap(props.location.longitude)}`}
            target="_blank"
            rel="noreferrer"
        >
            Get directions
        </a>
    );
};
