import React from "react";

import { ImageLinkBlock as Value } from "@reactivated";

import styles from "./index.module.scss";

interface Props {
    value: Value;
}

export const ImageLinkBlock = (props: Props) => {
    if (!props.value.image || !props.value.link) {
        return null;
    }
    return (
        <section className={styles.container}>
            <a href={props.value.link.url ?? undefined}>
                {props.value.image && (
                    <img className={styles.image} src={props.value.image.url} />
                )}
            </a>
        </section>
    );
};
