import React from "react";

import { LocationProximity } from "../../utils/models";
import { Address } from "../Address";
import { MakeReservationButton } from "../MakeReservationButton";
import { OrderNowButton } from "../OrderNowButton";
import { CurrentWaitTime } from "./CurrentWaitTime";
import { Distance } from "./Distance";
import { GetDirectionsLink } from "./GetDirectionsLink";
import { IndexGlyph } from "./IndexGlyph";
import { PhoneNumber } from "./PhoneNumber";
import { TodaysHours } from "./TodaysHours";

import styles from "./InfoWindowContent.module.scss";

type InfoWindowContentProps = LocationProximity & {
    index: number;
};

export const InfoWindowContent = (props: InfoWindowContentProps) => (
    <div className={styles.container}>
        <IndexGlyph index={props.index} />
        <div>
            <Address
                className={styles.address}
                location={{
                    line1: props.location.address1,
                    line2: props.location.address2 || "",
                    city: props.location.city,
                    state: props.location.state,
                    postcode: props.location.zip,
                }}
            />
            <Distance distance={props.distance} />
        </div>
        <hr />
        <TodaysHours
            className={styles.centerContent}
            location={props.location}
        />
        <hr />
        <PhoneNumber
            className={styles.centerContent}
            phoneNumber={props.location.phone}
        />
        <hr />
        <CurrentWaitTime location={props.location} />
        <MakeReservationButton location={props.location} />
        <OrderNowButton
            countryRootURL={props.location.countryRootURL}
            rlid={props.location.rlid}
            hasOnlineOrdering={props.location.hasOnlineOrdering}
        />
        <GetDirectionsLink
            className={styles.centerContent}
            location={props.location}
        />
    </div>
);
