import React from "react";

import { ManagementForm, templates, useFormSet } from "@reactivated";

import { FormField } from "../FormField";

import styles from "./SpecialOccasions.module.scss";

type OccasionFormSet = templates.EmailSignupPageTemplate["occasion_formset"];

type Page = {
    id: number;
    url: string;
    title: string;
};

export const SpecialOccasions = (props: {
    occasionFormSet: OccasionFormSet;
}) => {
    const occasionFormSet = useFormSet({
        formSet: props.occasionFormSet,
    });
    return (
        <section className={styles.occasions}>
            <h3>Get email reminders for your special occasions</h3>
            <p>
                Tell us about the special moments in your life so we can help
                you celebrate them.
            </p>
            <ManagementForm formSet={occasionFormSet.schema} />
            {occasionFormSet.forms.map((form, i) => (
                <fieldset key={i} className={styles.occasionFields}>
                    <FormField
                        wrapperClassName={styles.occasionField}
                        field={form.fields.occasion}
                    />
                    <FormField
                        wrapperClassName={styles.occasionField}
                        field={form.fields.relationship}
                    />
                    <FormField
                        wrapperClassName={styles.occasionField}
                        field={form.fields.month}
                    />
                    <FormField
                        wrapperClassName={styles.occasionField}
                        field={form.fields.day}
                    />
                </fieldset>
            ))}

            {/* Once we reach the max number of occasions, remove the add button */}
            {occasionFormSet.forms.length < occasionFormSet.schema.max_num && (
                <button
                    type="button"
                    className={styles.addOccasion}
                    onClick={occasionFormSet.addForm}
                >
                    Add
                </button>
            )}
        </section>
    );
};
