import React, { useId } from "react";

import { FooterColumn as FooterColumnValue } from "@reactivated";

import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import { FooterColumnContent } from "./FooterColumnContent";

import styles from "./FooterColumn.module.scss";

export const FooterColumn = (props: {
    index: number;
    value: FooterColumnValue;
}) => {
    const checkboxID = useId();
    const labelID = useId();
    const contentID = useId();
    // You're probably thinking that this is a bit of an odd way to build an
    // Accordion. Why use a label and hidden checkbox to control CSS styles,
    // instead of just using React state? Because of how the RL Ecom site uses
    // this footer. The ecom site extracts the footer HTML and injects it into
    // their own site. In that context, the footer still needs to be functional.
    // There is an tiny extra bit of JS that the Ecom site also loads, but this
    // is limited to just updating the aria properties. The rest of this works
    // solely based on HTML & CSS.
    //
    // See also: static/js/footer.ts
    return (
        <div
            className={concatClassNames([
                "js-rl-footer-column",
                styles.column,
                props.value.is_collapsable
                    ? styles.collapsable
                    : styles.noncollapsable,
                props.value.is_collapsable
                    ? "js-rl-footer-column--collapsable"
                    : null,
            ])}
        >
            <input
                id={checkboxID}
                type="checkbox"
                className={concatClassNames([
                    "js-rl-footer-column-checkbox",
                    styles.columnStateCheckbox,
                ])}
            />
            <label
                id={labelID}
                className={concatClassNames([
                    "js-rl-footer-column-label",
                    styles.columnTitle,
                ])}
                htmlFor={checkboxID}
                tabIndex={props.value.is_collapsable ? 0 : undefined}
                aria-controls={contentID}
            >
                {props.value.title}
            </label>
            {props.value.links && (
                <FooterColumnContent
                    value={props.value.links}
                    attrs={{
                        "id": contentID,
                        "className": concatClassNames([
                            "js-rl-footer-column-content",
                            styles.columnContent,
                        ]),
                        "aria-labelledby": labelID,
                        "role": "region",
                    }}
                />
            )}
        </div>
    );
};
