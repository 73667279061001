import React from "react";

import { FooterColumnContent as FooterColumnContentValue } from "@reactivated";

import { StreamField } from "@thelabnyc/thelabui/src/components/StreamField";
import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";
import { IntrinsicElementProps } from "@thelabnyc/thelabui/src/utils/types";

import { FooterLink } from "./FooterLink";
import { SocialMediaRow } from "./SocialMediaRow";

import styles from "./FooterColumnContent.module.scss";

export const FooterColumnContent = (props: {
    attrs: IntrinsicElementProps<HTMLUListElement>;
    value: FooterColumnContentValue;
}) => (
    <StreamField
        tagName={"ul"}
        attrs={{
            ...props.attrs,
            className: concatClassNames([
                styles.columnLinkList,
                props.attrs.className,
            ]),
        }}
        components={{
            link: FooterLink,
            social_media_row: SocialMediaRow,
        }}
        value={props.value}
    />
);
