import React from "react";

import { ServerCmsModelsPagesLocationsDirectoryPageLocation as TemplateLocation } from "@reactivated";

import { Location as APILocation } from "../../utils/models";

export const Address = (props: {
    className?: string;
    location: Pick<
        TemplateLocation,
        "line1" | "line2" | "city" | "state" | "postcode"
    >;
}) => (
    <address className={props.className}>
        {props.location.line1}
        <br />
        {props.location.line2 && (
            <>
                {props.location.line2}
                <br />
            </>
        )}
        {props.location.city}, {props.location.state} {props.location.postcode}
    </address>
);

export const APIAddress = (props: {
    className?: string;
    location: Pick<
        APILocation,
        "address1" | "address2" | "city" | "state" | "zip"
    >;
}) => (
    <Address
        {...props}
        location={{
            line1: props.location.address1,
            line2: props.location.address2 || "",
            city: props.location.city,
            state: props.location.state,
            postcode: props.location.zip,
        }}
    />
);
