import React from "react";

import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import styles from "./PhoneNumber.module.scss";

export const PhoneNumber = (props: {
    className?: string;
    phoneNumber: string;
}) => {
    return (
        <a
            href={`tel:${props.phoneNumber}`}
            className={concatClassNames([props.className, styles.phone])}
        >
            {props.phoneNumber}
        </a>
    );
};
