import React from "react";

import { SimpleLinkBlock } from "@reactivated";

import styles from "./FooterLink.module.scss";

export const FooterLink = (props: { value: SimpleLinkBlock }) => (
    <li>
        <a href={props.value.url ?? undefined} className={styles.columnLink}>
            {props.value.display_name}
        </a>
    </li>
);
