import React from "react";

import {
    MediaChooserBlock as MediaChooserBlockInterface,
    FullWidthMediaBlock as Value,
} from "@reactivated";

import { ImageChooserBlock } from "@thelabnyc/thelabui/src/components/ImageChooserBlock";
import { MediaChooserBlock } from "@thelabnyc/thelabui/src/components/MediaChooserBlock";

import { VideoWithPlayPauseButton } from "../VideoWithPlayPauseButton";

import styles from "./index.module.scss";

interface Props {
    value: Value;
    maxHeight: string;
}

export const FullWidthMediaBlock = (props: Props) => {
    /**
     * Assuming a StreamBlock inside of a StreamField with a max_num of 1
     */
    const mediaBlock = props.value[0].value;

    const renderMedia = (mediaBlock: MediaChooserBlockInterface) => {
        if (mediaBlock[0].type === "video") {
            return (
                <VideoWithPlayPauseButton
                    maxHeight={props.maxHeight}
                    value={mediaBlock[0].value}
                />
            );
        }

        if (mediaBlock[0].type === "image") {
            return (
                <ImageChooserBlock
                    {...mediaBlock[0]}
                    attrs={{
                        style: {
                            maxHeight: props.maxHeight,
                        },
                    }}
                    imageAttrs={{
                        style: {
                            aspectRatio:
                                mediaBlock[0].value.width /
                                mediaBlock[0].value.height,
                        },
                    }}
                />
            );
        }

        return <MediaChooserBlock value={mediaBlock} />;
    };

    return <section className={styles.root}>{renderMedia(mediaBlock)}</section>;
};
