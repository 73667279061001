import React from "react";

import { templates } from "@reactivated";

import { BackgroundStyle } from "../components/BackgroundStyle";
import { FooterLinks } from "../components/EmailSignupForm/FooterLinks";
import { Legal } from "../components/EmailSignupForm/Legal";
import { PageContentContainer } from "../components/PageContentContainer";
import { PageSkeleton } from "../components/PageSkeleton";
import { Toolbox } from "../components/Toolbox";

export const Template = (
    props: templates.EmailSignupPageLandingPageTemplate,
) => (
    <React.StrictMode>
        <PageSkeleton {...props.page}>
            <BackgroundStyle style="wood-texture">
                <Toolbox value={props.page.body.value} />
                <Toolbox value={props.page.confirmation_content.value} />
                <PageContentContainer>
                    <Legal postConsentText={props.page.post_consent_text} />
                    <FooterLinks
                        faqPage={props.page.faq_page}
                        unsubscribePage={props.page.unsubscribe_page}
                    />
                </PageContentContainer>
            </BackgroundStyle>
        </PageSkeleton>
    </React.StrictMode>
);
