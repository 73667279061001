import React from "react";

import { MediaGridBlock as Value } from "@reactivated";

import { StreamField } from "@thelabnyc/thelabui/src/components/StreamField";

import { BreakpointState, useCurrentBreakpoint } from "../../hooks/layout";
import * as layout from "../../styles/layout";
import { PageContentContainer } from "../PageContentContainer";
import { BackgroundVideoTile } from "./BackgroundVideoTile";
import { ImageTile } from "./ImageTile";
import { VideoTile } from "./VideoTile";

import styles from "./index.module.scss";

interface TileRow {
    tiles: Value;
    width: number;
}

const getTileWidth = (
    tile: Value[number],
    viewport: BreakpointState,
): number => {
    const tileWidth = viewport.belowMedium
        ? tile.value.mobile_width
        : tile.value.desktop_width;
    return tileWidth ? parseInt(tileWidth, 10) : layout.totalColumns;
};

const groupRows = (tiles: Value, viewport: BreakpointState): TileRow[] => {
    const rows: TileRow[] = [];
    let rowTiles: Value = [];
    let rowWidth = 0;

    const finishRow = () => {
        rows.push({
            tiles: rowTiles,
            width: rowWidth,
        });
        rowTiles = [];
        rowWidth = 0;
    };

    const pushTile = (tile: Value[number], width: number) => {
        rowTiles.push(tile);
        rowWidth += width;
    };

    for (const tile of tiles) {
        const tileWidth = getTileWidth(tile, viewport);
        if (rowWidth + tileWidth > layout.totalColumns) {
            finishRow();
        }
        pushTile(tile, tileWidth);
    }
    finishRow();
    return rows;
};

const Row = (props: { tiles: Value; width: number }) => {
    return (
        <div className={styles.mediaGridBlockRow} data-width={props.width}>
            <StreamField
                tagName={React.Fragment}
                components={{
                    image: (blockProps) => (
                        <ImageTile
                            numSiblings={props.tiles.length - 1}
                            {...blockProps}
                        />
                    ),
                    background_video: (blockProps) => (
                        <BackgroundVideoTile
                            numSiblings={props.tiles.length - 1}
                            {...blockProps}
                        />
                    ),
                    video: (blockProps) => (
                        <VideoTile
                            numSiblings={props.tiles.length - 1}
                            {...blockProps}
                        />
                    ),
                }}
                value={props.tiles}
            />
        </div>
    );
};

export const MediaGridBlock = (props: { value: Value }) => {
    const viewport = useCurrentBreakpoint();
    const rows = groupRows(props.value, viewport);
    return (
        <PageContentContainer className={styles.mediaGridBlock}>
            {rows.map((row, i) => (
                <Row key={i} {...row} />
            ))}
        </PageContentContainer>
    );
};
