import React, { useEffect, useState } from "react";

import { unsubscribeEmail } from "../../utils/api";
import { UnsubscribeResponse } from "../../utils/models";
import { PageContentContainer } from "../PageContentContainer";
import { Confirmation } from "./Confirmation";
import { Form } from "./Form";

const getEmailFromQueryString = (): string | null => {
    const url = new URL(window.location.href);
    return url.searchParams.get("utm_unsub");
};

export const UnsubscribeBlock = () => {
    const [email, setEmail] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submitStatus, setSubmitStatus] =
        useState<UnsubscribeResponse | null>(null);

    // Prefill the form with the email given in the query string.
    useEffect(() => {
        const emailParam = getEmailFromQueryString();
        if (emailParam && email === "") {
            setEmail(emailParam);
        }
    }, []);

    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const resp = await unsubscribeEmail(email);
        setSubmitStatus(resp);
    };

    return (
        <PageContentContainer>
            {!submitStatus || !submitStatus.isValid ? (
                <Form
                    email={email}
                    setEmail={setEmail}
                    error={submitStatus ? submitStatus.errors[0] : null}
                    onSubmit={onSubmit}
                    isSubmitting={isSubmitting}
                />
            ) : (
                <Confirmation />
            )}
        </PageContentContainer>
    );
};
