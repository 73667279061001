import React from "react";

import { FooterColumnList as FooterColumnListValue } from "@reactivated";

import { FooterColumn } from "./FooterColumn";

import styles from "./FooterColumnList.module.scss";

export const FooterColumnList = (props: { value: FooterColumnListValue }) => (
    <div className={styles.columns}>
        {props.value.map((column, i) => (
            <FooterColumn key={i} index={i} value={column} />
        ))}
    </div>
);
