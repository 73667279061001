import React from "react";

import { SitemapSection as SitemapSectionValue } from "@reactivated";

import styles from "./SitemapSection.module.scss";

export const SitemapSection = (props: { value: SitemapSectionValue }) => (
    <section>
        <h2 className={styles.title}>{props.value.title}</h2>
        <ul className={styles.list}>
            {(props.value.links || []).map((link) => (
                <li key={link.url} className={styles.listItem}>
                    <a href={link.url || undefined}>{link.display_name}</a>
                </li>
            ))}
        </ul>
    </section>
);
