import * as Sentry from "@sentry/react";
import React from "react";
import { createRoot } from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";

import { Provider, getServerData, getTemplate } from "@reactivated";

import { activateTranslations } from "@thelabnyc/thelabui/src/utils/i18n";

import "./styles/global.scss";

const { props, context } = getServerData();

if (context.SENTRY_DSN) {
    Sentry.init({
        dsn: context.SENTRY_DSN,
        release: context.CI_COMMIT_SHA,
        environment: context.CI_COMMIT_REF_SLUG,
        sampleRate: 0.01, // Capture 1% of errors
        integrations: [],
        ignoreErrors: [
            // Random plugins/extensions
            "top.GLOBALS",
            // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
            "originalCreateNotification",
            "canvas.contentDocument",
            "MyApp_RemoveAllHighlights",
            "http://tt.epicplay.com",
            "Can't find variable: ZiteReader",
            "jigsaw is not defined",
            "ComboSearch is not defined",
            "http://loading.retry.widdit.com/",
            "atomicFindClose",
            // Facebook borked
            "fb_xd_fragment",
            // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
            // reduce this. (thanks @acdha)
            // See http://stackoverflow.com/questions/4113268
            "bmi_SafeAddOnload",
            "EBCallBackMessageReceived",
            // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
            "conduitPage",
        ],
        denyUrls: [
            // Facebook flakiness
            /graph\.facebook\.com/i,
            // Facebook blocked
            /connect\.facebook\.net\/en_US\/all\.js/i,
            // Woopra flakiness
            /eatdifferent\.com\.woopra-ns\.com/i,
            /static\.woopra\.com\/js\/woopra\.js/i,
            // Chrome extensions
            /extensions\//i,
            /^chrome:\/\//i,
            /^chrome-extension:\/\//i,
            // Other plugins
            /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
            /webappstoolbarba\.texthelp\.com\//i,
            /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
            // Google maps
            /maps\.googleapis\.com\//i,
        ],
    });
}

activateTranslations(context.i18n);

const main = async () => {
    const Template = await getTemplate(context);

    const root = createRoot(document.getElementById("root")!);
    root.render(
        <HelmetProvider>
            <Provider value={context}>
                <Template {...props} />
            </Provider>
        </HelmetProvider>,
    );
};

await main();
