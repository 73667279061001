import React from "react";

import {
    ArticlePreviewGridBlock as ArticlePreviewGridBlockValue,
    _Article,
    templates,
} from "@reactivated";

import { ImageChooserBlock } from "@thelabnyc/thelabui/src/components/ImageChooserBlock";
import { RichText } from "@thelabnyc/thelabui/src/components/RichText";
import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import { ArticlePreviewGridBlock } from "../components/ArticlePreviewGridBlock";
import { BackgroundStyle } from "../components/BackgroundStyle";
import { HeadingBlock } from "../components/HeadingBlock";
import { PageContentContainer } from "../components/PageContentContainer";
import { PageSkeleton } from "../components/PageSkeleton";
import { PressHero } from "../components/PressHero";

import styles from "../styles/templates/PressSectionPageTemplate.module.scss";

const convertArticles = (
    pressReleases: templates.PressSectionPageTemplate["press_releases"],
): ArticlePreviewGridBlockValue => {
    return {
        articles: pressReleases.articles.map((article): _Article => {
            return {
                link: {
                    id: article.id,
                    url: article.url,
                    title: article.title,
                },
                image: article.feature_image?.block || null,
                date: article.date,
                title: article.title,
            };
        }),
        see_all_link: pressReleases.see_all_link
            ? {
                  id: pressReleases.see_all_link.id,
                  url: pressReleases.see_all_link.url,
                  title: pressReleases.see_all_link.title,
              }
            : null,
    };
};

const humanFileSize = (bytes: number, decimalPoints = 1): string => {
    const units = ["KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const thresh = 1000;
    if (Math.abs(bytes) < thresh) {
        return `${bytes} B`;
    }
    let u = -1;
    const r = 10 ** decimalPoints;
    do {
        bytes /= thresh;
        ++u;
    } while (
        Math.round(Math.abs(bytes) * r) / r >= thresh &&
        u < units.length - 1
    );
    return `${bytes.toFixed(decimalPoints)} ${units[u]}`;
};

export const Template = (props: templates.PressSectionPageTemplate) => (
    <React.StrictMode>
        <PageSkeleton {...props.page}>
            <BackgroundStyle style="wood-texture">
                <PressHero title={props.page.title} />
                <HeadingBlock
                    value={{
                        heading_text: "Press Releases",
                        size: "h2",
                        style: "rope",
                    }}
                />
                <PageContentContainer>
                    <ArticlePreviewGridBlock
                        value={convertArticles(props.press_releases)}
                    />
                </PageContentContainer>

                {props.page.contact_info && (
                    <>
                        <HeadingBlock
                            value={{
                                heading_text: "Press Inquiries",
                                size: "h2",
                                style: "rope",
                            }}
                        />
                        <PageContentContainer>
                            <RichText
                                value={props.page.contact_info}
                                className={concatClassNames([
                                    "rich-text",
                                    styles.contactInfo,
                                ])}
                            />
                        </PageContentContainer>
                    </>
                )}

                {props.page.media_download_tiles.length > 0 && (
                    <>
                        <HeadingBlock
                            value={{
                                heading_text: "Media Downloads",
                                size: "h2",
                                style: "rope",
                            }}
                        />
                        <PageContentContainer className={styles.downloads}>
                            {props.page.media_download_tiles.map((tile) => (
                                <div
                                    key={tile.title}
                                    className={styles.download}
                                >
                                    <div className={styles.downloadPreview}>
                                        <h3>
                                            <svg viewBox="0 0 512 512">
                                                <path d="M256 464a208 208 0 1 1 0-416 208 208 0 1 1 0 416zM256 0a256 256 0 1 0 0 512A256 256 0 1 0 256 0zM376.9 294.6c4.5-4.2 7.1-10.1 7.1-16.3c0-12.3-10-22.3-22.3-22.3H304V160c0-17.7-14.3-32-32-32l-32 0c-17.7 0-32 14.3-32 32v96H150.3C138 256 128 266 128 278.3c0 6.2 2.6 12.1 7.1 16.3l107.1 99.9c3.8 3.5 8.7 5.5 13.8 5.5s10.1-2 13.8-5.5l107.1-99.9z" />
                                            </svg>
                                            <span>{tile.title}</span>
                                        </h3>
                                        {tile.image && (
                                            <ImageChooserBlock
                                                value={tile.image}
                                            />
                                        )}
                                        <p>
                                            {`.${tile.file_extension}`} File,{" "}
                                            {humanFileSize(tile.file_size)}
                                        </p>
                                    </div>
                                    <a
                                        href={tile.url}
                                        download={true}
                                        className={styles.downloadBtn}
                                    >
                                        Download
                                    </a>
                                </div>
                            ))}
                        </PageContentContainer>
                    </>
                )}
            </BackgroundStyle>
        </PageSkeleton>
    </React.StrictMode>
);
