import React from "react";

import { LocationProximity, RLID, isoRLID } from "../../utils/models";
import { LocationListItem } from "./LocationListItem";

import styles from "./LocationList.module.scss";

export type LocationListProps = {
    locations: LocationProximity[];
    onSelect: (rlid: RLID) => void;
};

export const LocationList = (props: LocationListProps) => (
    <ol className={styles.container}>
        {props.locations.map((locProx, i) => (
            <LocationListItem
                key={`${isoRLID.unwrap(locProx.location.rlid)}`}
                index={i}
                onSelect={() => {
                    props.onSelect(locProx.location.rlid);
                }}
                {...locProx}
            />
        ))}
    </ol>
);
