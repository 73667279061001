import React from "react";

import { LocationProximity } from "../../utils/models";
import { Address } from "../Address";
import { MakeReservationButton } from "../MakeReservationButton";
import { OrderNowButton } from "../OrderNowButton";
import { CurrentWaitTime } from "./CurrentWaitTime";
import { Distance } from "./Distance";
import { GetDirectionsLink } from "./GetDirectionsLink";
import { IndexGlyph } from "./IndexGlyph";
import { PhoneNumber } from "./PhoneNumber";
import { TodaysHours } from "./TodaysHours";

import styles from "./LocationListItem.module.scss";

type LocationListItemProps = LocationProximity & {
    index: number;
    onSelect: () => void;
};

export const LocationListItem = (props: LocationListItemProps) => {
    return (
        <li onClick={props.onSelect} className={styles.listItem}>
            <div className={styles.glyphWrapper}>
                <IndexGlyph index={props.index} />
                <Distance
                    className={styles.distance}
                    distance={props.distance}
                />
            </div>
            <div className={styles.detailsWrapper}>
                <div>
                    <Address
                        className={styles.address}
                        location={{
                            line1: props.location.address1,
                            line2: props.location.address2 || "",
                            city: props.location.city,
                            state: props.location.state,
                            postcode: props.location.zip,
                        }}
                    />
                    <TodaysHours location={props.location} />
                    <PhoneNumber
                        className={styles.phone}
                        phoneNumber={props.location.phone}
                    />
                </div>
                <CurrentWaitTime location={props.location} />
                <MakeReservationButton location={props.location} />
                <OrderNowButton
                    countryRootURL={props.location.countryRootURL}
                    rlid={props.location.rlid}
                    hasOnlineOrdering={props.location.hasOnlineOrdering}
                />
                <GetDirectionsLink
                    className={styles.link}
                    location={props.location}
                />
            </div>
        </li>
    );
};
