import * as Dialog from "@radix-ui/react-dialog";
import { VisuallyHidden } from "@react-aria/visually-hidden";
import React from "react";

import { Clickable } from "@thelabnyc/thelabui/src/components/Clickable";
import { Svg } from "@thelabnyc/thelabui/src/components/Svg";
import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import styles from "./index.module.scss";

export const ModalRoot = Dialog.Root;

export const ModalTrigger = Dialog.Trigger;

export const Modal = (
    props: React.PropsWithChildren<{
        /** For accessibility purposes. Give a useful title please! */
        contentLabel: string | undefined;
        className?: string | undefined;
        closeClassName?: string | undefined;
        overlayClassName?: string | undefined;
    }>,
) => {
    return (
        <Dialog.Portal>
            <Dialog.Overlay
                className={concatClassNames([
                    styles.overlay,
                    props.overlayClassName,
                ])}
            />
            <Dialog.Content
                className={concatClassNames([styles.content, props.className])}
                aria-describedby={undefined}
            >
                <VisuallyHidden>
                    <Dialog.Title>{props.contentLabel}</Dialog.Title>
                </VisuallyHidden>

                <Dialog.Close asChild>
                    <Clickable
                        className={concatClassNames([
                            styles.close,
                            props.closeClassName,
                        ])}
                    >
                        <Svg name="x" />
                    </Clickable>
                </Dialog.Close>

                {props.children}
            </Dialog.Content>
        </Dialog.Portal>
    );
};
