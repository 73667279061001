import {
    format as formatDate,
    formatISO as formatISODate,
    parseISO as parseISODate,
} from "date-fns";
import React from "react";

import { CmsModelsPagesPressUtilsPressReleaseTile as PressReleaseTile } from "@reactivated";

import style from "./ListItem.module.scss";

export const ListItem = (props: { article: PressReleaseTile }) => {
    const pubDate = parseISODate(props.article.date);
    const isoPubDate = formatISODate(pubDate);
    return (
        <article
            className={style.row}
            itemScope={true}
            itemType="http://schema.org/Article"
        >
            <div className={style.pubDate}>
                <time
                    dateTime={isoPubDate}
                    content={isoPubDate}
                    itemProp="datePublished"
                >
                    {formatDate(pubDate, "M.d.yy")}
                </time>
            </div>
            <div className={style.title}>
                <h2>{props.article.title}</h2>
                <a aria-label={props.article.title} href={props.article.url}>
                    {"Learn More"}
                </a>
            </div>
        </article>
    );
};
