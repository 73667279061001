import React from "react";

import { ServerCmsModelsPagesLocationsDirectoryPageOperatingHours as OperatingHours } from "@reactivated";

import styles from "./Hours.module.scss";

export const Hours = (props: { hours: OperatingHours[] }) => (
    <div>
        <h2 className={styles.heading}>Hours of Operation - Dine-in & To-Go</h2>
        {props.hours.map((category) => {
            // Only display the "Operation" category
            if (category.type_name === "Operation") {
                return (
                    <div key={category.type_name}>
                        <dl className={styles.wrapper}>
                            {category.days.map((day) => (
                                <React.Fragment key={day.day_name}>
                                    <dt>{day.day_name}</dt>
                                    <dd>
                                        {day.hours.map((period) => (
                                            <div key={period}>{period}</div>
                                        ))}
                                    </dd>
                                </React.Fragment>
                            ))}
                        </dl>
                    </div>
                );
            }
        })}
    </div>
);
