import React from "react";

import { SitemapBlock as SitemapBlockValue } from "@reactivated";

import { PageContentContainer } from "../PageContentContainer";
import { SitemapSection } from "./SitemapSection";

import styles from "./index.module.scss";

export const SitemapBlock = (props: { value: SitemapBlockValue }) => (
    <PageContentContainer>
        <h1 className={styles.title}>{props.value.title}</h1>
        <nav className={styles.sections}>
            {(props.value.sections || []).map((section, i) => (
                <SitemapSection key={i} value={section} />
            ))}
        </nav>
    </PageContentContainer>
);
