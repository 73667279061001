import React from "react";

import { LinkRowBlock as Value, _LinkRowItem } from "@reactivated";

import { ImageChooserBlock } from "@thelabnyc/thelabui/src/components/ImageChooserBlock";

import { PageContentContainer } from "../PageContentContainer";

import styles from "./index.module.scss";

interface Props {
    value: Value;
}

interface IGridItem {
    value: _LinkRowItem;
}

export const LinkRowItem = (props: IGridItem) => {
    const altText = props.value.image ? props.value.image.title : undefined;
    if (!props.value.link) {
        return null;
    }
    return (
        <a className={styles.link} href={props.value.link.url ?? undefined}>
            {props.value.image && (
                <ImageChooserBlock
                    value={props.value.image}
                    imageAttrs={{
                        alt: altText,
                    }}
                />
            )}
            {props.value.link && (
                <h3 className={styles.linkTitle}>
                    {props.value.link.display_name}
                </h3>
            )}
        </a>
    );
};

export const LinkRowBlock = (props: Props) => {
    if (!props.value.links) {
        return null;
    }
    return (
        <PageContentContainer>
            <section className={styles.wrapper}>
                <h2 className={styles.heading}>{props.value.heading}</h2>
                <div className={styles.container}>
                    {props.value.links.map((link, i) => {
                        return <LinkRowItem key={i} value={link} />;
                    })}
                </div>
                {props.value.fineprint && (
                    <p className={styles.fineprint}>{props.value.fineprint}</p>
                )}
            </section>
        </PageContentContainer>
    );
};
