import { BackgroundStyle } from "@client/components/BackgroundStyle";
import React from "react";

import { templates } from "@reactivated";

import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import { PageSkeleton } from "../components/PageSkeleton";

import styles from "../styles/templates/Error404Template.module.scss";

export const Template = (props: templates.Error404Template) => (
    <React.StrictMode>
        <PageSkeleton title={props.title}>
            <BackgroundStyle style="wood-texture">
                <section className={styles.container}>
                    <h1 className={styles.heading}>
                        The page you are looking for does not exist, sorry.
                    </h1>
                    <a
                        href="/"
                        className={concatClassNames(["button", styles.button])}
                    >
                        Return to Homepage
                    </a>
                </section>
            </BackgroundStyle>
        </PageSkeleton>
    </React.StrictMode>
);
