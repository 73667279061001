import React from "react";

import { templates } from "@reactivated";

import { range } from "@thelabnyc/thelabui/src/utils/functional";
import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import styles from "./Pagination.module.scss";

export const Pagination = (props: {
    page: templates.PressReleaseListPageTemplate["page"];
    articles: templates.PressReleaseListPageTemplate["press_releases"];
}) => {
    const pageNums = range(props.articles.num_pages).map((i) => i + 1);

    const getPageURL = (pageNum: number): string => {
        const url = new URL(props.page.url);
        if (pageNum === 1) {
            url.searchParams.delete("page");
        } else {
            url.searchParams.set("page", `${pageNum}`);
        }
        return url.toString();
    };

    return (
        <ul className={styles.list}>
            <li className={styles.item}>
                <a
                    className={styles.first}
                    aria-label="First Page"
                    href={getPageURL(1)}
                >
                    <svg viewBox="0 0 320 512">
                        <path d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
                    </svg>
                </a>
            </li>
            {pageNums.map((pageNum) => {
                const isCurrent = pageNum === props.articles.current_page;
                return (
                    <li
                        key={pageNum}
                        className={concatClassNames([
                            styles.item,
                            isCurrent ? styles.current : null,
                        ])}
                    >
                        <a
                            aria-current={isCurrent ? "page" : undefined}
                            aria-label={`Page ${pageNum}`}
                            href={getPageURL(pageNum)}
                        >
                            {pageNum}
                        </a>
                    </li>
                );
            })}
            <li className={styles.item}>
                <a
                    className={styles.last}
                    aria-label="Last Page"
                    href={getPageURL(props.articles.num_pages)}
                >
                    <svg viewBox="0 0 320 512">
                        <path d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
                    </svg>
                </a>
            </li>
        </ul>
    );
};
